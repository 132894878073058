import dynamic from "next/dynamic";
import React from "react";

const loaderComponent = (compType: string) => {
  const Component = dynamic(() =>
    import(`@/components/Paragraphs/${compType}/${compType}`).catch((error) => {
      console.error("Error loading component:", error);
      return { default: () => <div>Component not found</div> };
    })
  );

  return Component;
};

export default loaderComponent;

export const loaderFormBit = (compType: string) => {
  const Component = dynamic(() =>
    import(`@/components/reusable/FormBits/${compType}/${compType}`).catch(
      (error) => {
        console.error("Error loading component:", error);
        return { default: () => <div>Component not found</div> };
      }
    )
  );

  return Component;
};
