'use client'

import { styled } from "@mui/material/styles";
import theme from "@/theme/theme";
import { Container } from "@mui/material";

export const SearchContainer = styled(Container)(() => ({
    width: "90%",
    padding: `${theme.sizes.doublePadding} 0 ${theme.sizes.basePadding} 0`,
    [`${theme.mediaQueries.desktopM}`]: {
        padding: 0,
        margin: 0,
        maxWidth: "824px",
        marginTop: `-27px`,
    },
    "a": {
        textDecoration: "none"
    }
}));