"use client";

import theme from "@/theme/theme";
import styled from "@emotion/styled";

export const StyledTwoButtons = styled("section")`
  .MuiBox-root,
  .MuiContainer-root {
    width: 100%;
    justify-content: center;
    padding-top: 0;
    .content {
      flex-direction: row;
      justify-content: center;
      gap: ${theme.sizes.basePadding};
      padding: 0;
      .MuiBox-root {
        width: fit-content;
        .MuiButton-root {
          padding: ${theme.sizes.halfBasePadding} ${theme.sizes.quadricPadding};
          margin: 0;
          &.MuiButton-outlined {
            background-color: ${theme.colors.white};
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
`;
