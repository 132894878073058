import { drupal } from "@/lib/drupal";

export function convertEntityUri(uri: string): string {
  const baseUrl = drupal?.baseUrl || "";

  if (uri.startsWith("entity:")) {
    const [entityType, entityId] = uri.replace("entity:", "").split("/");

    switch (entityType) {
      case "node":
        return `${baseUrl}/node/${entityId}`;
      case "taxonomy_term":
        return `${baseUrl}/taxonomy/term/${entityId}`;
      default:
        return `${baseUrl}/entity/${entityId}`;
    }
  }

  if (uri.startsWith("internal:")) {
    const internalPath = uri.replace("internal:", "");
    return `${baseUrl}${internalPath}`;
  }
  if (uri.startsWith("/sites/default/files/")) {
    const mediaPath = uri.replace("/sites/default/files/", "");
    return `${baseUrl}/sites/default/files/${mediaPath}`;
  }
  return uri;
}
