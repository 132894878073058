"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { StyledFiltersContainer, StyledLottieAnimation, StyledNoResults, StyledSearchResults, StyledSearchResultsTitle } from "./styles";
import { useFormContext } from "../reusable/FormBits/FormContext";
import Popup from "../reusable/Popup/Popup";
import MiniContent from "../reusable/MiniContent/MiniContent";
import { useTranslations } from "next-intl";
import SearchResultItem from "./SearchResultItem";
import SearchGroupItem from "./SearchGroupItem";
import SearchFilters from "./SearchFilters";
import { Typography } from "@mui/material";
import { imageUrl, isLocalHost } from "@/theme/theme";
import { useIsDesktop } from "@/common/MediaQueries";
import { InfoItemType, SearchResultsProps } from "./types";
import TwoButtons from "../TwoButtons/TwoButtons";

const imgUrl = `${imageUrl}images/SearchSaving/noResults.png`;
const loaderUrl = `${imageUrl}animations/diamondLoader.json`;
const getInnterestsUrl = `${isLocalHost ? "/api" : "/_api"}/poalim_nabat/get_interests`;

const SearchResults: React.FC<SearchResultsProps> = ({ twoButtons }) => {
  const { filteredResults, groups, filters, getActiveTabValues, activeTabIndex } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [currInfoItem, setCurrInfoItem] = useState<InfoItemType>({});
  const [popupData, setPopupData] = useState<any>({});
  const t = useTranslations("Nabat");
  const popupTitle = t("Popup.title");
  const popupCollapseTitle = t("Popup.collapseTitle");
  const noResultsText = t("noResultsText");
  const searchResultsAmount = t("searchResultsAmount");
  const isDesktop = useIsDesktop();

  useEffect(() => { 
    if (Object.keys(currInfoItem).length > 0) {
      fetchPopupInfo();
    }
  }, [currInfoItem]);

  if (!filteredResults && !groups) return null;

  const handlePopupOpen = (infoItem: InfoItemType) => {
    setIsOpen(true);
    setCurrInfoItem(infoItem);
  };

  const fetchPopupInfo = async () => {
    if (!currInfoItem) return false;
    try {
      const activeTabValues = getActiveTabValues(
        activeTabIndex
      );
      const amount = activeTabValues?.depositingAmount
      ? parseInt(activeTabValues.depositingAmount.toString())
      : 1000;
        
      const paramObject = {
        depositingAmount: amount,
        systemCode: currInfoItem?.systemCode,
        productNumber: currInfoItem?.productNumber,
        savingPeriod: currInfoItem?.investmentPeriodFrom,
        timeUnitCode: currInfoItem?.periodDurationCode,
      };

      const response = await fetch(getInnterestsUrl, { // test and prod url
        method: "POST",
        body: JSON.stringify(paramObject),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPopupData(data?.results);
    } catch (error) {
      console.error("Error sending form:", error);
    }
  };

  return (
    <>
    <StyledSearchResults id="nbt-search-results">
      <StyledFiltersContainer>
      {filteredResults && filters && filters?.length > 0 && (
        <>
          <SearchFilters filterOptions={filters} />
          <StyledSearchResultsTitle variant="body1">
            {`${filteredResults?.length || 0} ${searchResultsAmount}`}
          </StyledSearchResultsTitle>
        </>
      )}
        
      </StyledFiltersContainer>
      {filteredResults &&
        filteredResults?.length > 0 &&
        filteredResults.map(({ tagFlag, items, itemInfo }, index) => {
          if (items?.length > 0) {
            return (
              <SearchResultItem
                key={`search-result-item${index}`}
                tagFlag={tagFlag}
                items={items}
                index={index}
                onPopupOpen={() => handlePopupOpen(itemInfo)}
              />
            );
          }
          return null;
        })}
      {groups &&
        groups.map((data, index) => {
          if (Object.keys(data)?.length > 0) {
            return (
              <SearchGroupItem
                key={`search-group-item${index}`}
                data={data}
                index={index}
                onPopupOpen={handlePopupOpen}
              />
            );
          }
          return null;
        })}
      {((filteredResults && filteredResults.length === 0) ||
        (groups && groups.length === 0)) && (
        <StyledNoResults>
          <Image
            src={imgUrl}
            alt="No results"
            width={isDesktop ? 237 : 162}
            height={isDesktop ? 234 : 160}
          />
          <Typography variant="body1">{noResultsText}</Typography>
        </StyledNoResults>
      )}
      {isOpen && (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen} additionalClose={() => {setPopupData({}); setCurrInfoItem({})}}>
          {Object.keys(popupData).length === 0 ? (
            <StyledLottieAnimation
              src={loaderUrl}
            />
          ) : (
            <MiniContent
              data={popupData}
              title={popupData?.main_title || popupTitle}
              collapseTitle={popupCollapseTitle}
            />
          )}
        </Popup>
      )}
    </StyledSearchResults>
    { twoButtons && !(filteredResults && filteredResults.length === 0) && 
      <TwoButtons {...twoButtons}/>
    }
    </>
  );
};

export default SearchResults;
