"use client";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme, { imageUrl } from "@/theme/theme";
import { GradientOverlayProps, heroContainerProps } from "./types";

export const HeroItemVideo = styled(Box, {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "variant",
})<heroContainerProps>(({ height, variant }) => ({
  display: "flex",
  height: height ? height : "auto",
  padding: 0,
  h2: {
    color: variant === "light" ? theme.colors.mainGrey : theme.colors.white,
  },
  ".hero-video": {
    height: height ? height : "auto",
  },
  [`${theme.mediaQueries.desktopM}`]: {
    marginTop: "unset",
    padding: theme.sizes.triplePadding,
  },
  ".content": {
    marginTop: "auto",
    marginBottom: theme.sizes.containerPadding,
    padding: theme.sizes.basePadding,
    width: "90%",
    h2: {
      color: variant === "light" ? theme.colors.mainGrey : theme.colors.white,
    },
    [`${theme.mediaQueries.desktopM}`]: {
      marginTop: "unset",
      width: "510px",
      padding: theme.sizes.triplePadding,
    },
  },
}));

export const HeroItemImage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "variant",
})<heroContainerProps>(({ height, variant }) => ({
  display: "flex",
  padding: 0,
  height: height ? height : "auto",
  backgroundImage: `url("${imageUrl}images/homepage/Hero/heroSection.png")`,
  backgroundPosition: "right 80% top 0px",
  backgroundSize: "auto",
  [`${theme.mediaQueries.desktopM}`]: {
    backgroundPosition: "right 50% top 0px",
    height: height ? height : "auto",
  },
  ".MuiContainer-root": {
    paddingTop: "0",
    ".content": {
      padding: theme.sizes.basePadding,
      order: 2,
      width: "90%",
      h2: {
        color: variant === "light" ? theme.colors.mainGrey : theme.colors.white,
      },
      [`${theme.mediaQueries.desktopM}`]: {
        order: 1,
        padding: theme.sizes.triplePadding,
        width: "510px",
      },
    },
    [`${theme.mediaQueries.desktopM}`]: {
      paddingTop: "60px",
    },
  },
  ".img-wrapper": {
    order: 1,
    overflow: "hidden",
    height: "60%",
    margin: "0 auto",
    img: {
      width: "auto",
      height: "140%",
      marginTop: "auto",
      [`${theme.mediaQueries.desktopM}`]: {
        width: "revert-layer",
        height: "revert-layer",
      },
    },
    [`${theme.mediaQueries.desktopM}`]: {
      order: 2,
      height: "100%",
      margin: "auto",
      marginTop: "12px",
      overflow: "visible",
    },
  },
}));

export const GradientOverlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== "variant",
})<GradientOverlayProps>(({ variant }) => ({
  position: "absolute",
  background:
    variant === "dark"
      ? theme.colors.darkOverlayGradient
      : theme.colors.lightOverlayGradient,
  height: "100%",
  width: "100%",
  zIndex: "1",
  top: 0,
  left: 0,
}));
