import React from "react";
import { Typography } from "@mui/material";
import {
  FlagTag,
  StyledSearchResultItem,
  StyledSearchResultProperty,
} from "./styles";
import { SearchResultItemProps } from "./types";
import { StarsIcon } from "../reusable/IconsComponents";
import IconWithTooltip from "../reusable/IconWithTooltip/IconWithTooltip";
import { InformationIcon } from "../reusable/IconsComponents";
import { ArrowLinkButton } from "../reusable/ArrowLink/ArrowLink";
import { useTranslations } from "next-intl";

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  tagFlag,
  items,
  index,
  onPopupOpen,
}) => {
  const t = useTranslations("Nabat");
  const flagText = t("flagText");
  return (
    <StyledSearchResultItem>
      {tagFlag === 1 && (
        <FlagTag>
          {flagText}
          <StarsIcon />
        </FlagTag>
      )}
      {items?.map(({ text, text1, text2, title, titleTooltip, textTooltip }, i) => {
        if (title || text || text1 || text2)
          return (
            <StyledSearchResultProperty
              key={`search-result-property-${index}-${i}`}
            >
              <Typography variant="h5">
                {'\u202A' + title + '\u202C' }
                {i === 1 && (
                  <ArrowLinkButton onClick={onPopupOpen} variant="ghost" />
                )}
                { titleTooltip && (
                  <IconWithTooltip
                    icon={<InformationIcon />}
                    tooltipText={titleTooltip}
                    placement="top-end"
                  />
                )}
              </Typography>
              { text && <Typography variant="body1">{ '\u202A' + text + '\u202C' }{ textTooltip && (
                  <IconWithTooltip
                    icon={<InformationIcon />}
                    tooltipText={textTooltip}
                    placement="top-start"
                  />
                )}</Typography> }
              { text1 &&<Typography variant="body1">{ '\u202A' + text1 + '\u202C' }{ textTooltip && (
                  <IconWithTooltip
                    icon={<InformationIcon />}
                    tooltipText={textTooltip}
                    placement="top-start"
                  />
                )}</Typography> }
              { text2 && <Typography variant="body1">{ '\u202A' + text2 + '\u202C' }{ textTooltip && (
                  <IconWithTooltip
                    icon={<InformationIcon />}
                    tooltipText={textTooltip}
                    placement="top-start"
                  />
                )}</Typography> }
              
            </StyledSearchResultProperty>
          );
      })}
    </StyledSearchResultItem>
  );
};

export default React.memo(SearchResultItem);
