import React, { Suspense } from "react";
import { ToggleTabItem } from "./styles";
import { loaderFormBit } from "../reusable/LoaderComponent";
import FormBitWrapper from "../reusable/FormBits/FormBitWrapper";
import { capitalizeFirstLetter } from "@/lib/utils/stringMethods";
import { ValidationRule } from "../reusable/FormBits/types";
import { useTranslations } from "next-intl";
import { ToggleTabContentProps } from "./types";

const ToggleTabContent = React.memo(
  ({ items, outerIndex }: ToggleTabContentProps) => {
    const t = useTranslations("Nabat");
    const errorRequried = t("FormErrors.required");
    const errorMin50= t("FormErrors.min50");
    const errorMin100= t("FormErrors.min100");
    const errorMax7500 = t("FormErrors.max7500");
    const errorMax = t("FormErrors.max");
    const getValidationRules = (item: any): ValidationRule[] => {
      const rules: ValidationRule[] = [];
      if (item.required) {
        rules.push({
          type: "required",
          message: errorRequried,
        });
      }
      if (outerIndex === 1 && item.type === "number") { // only the second tab (monthly) number input number input had min 50 and max 7500
        rules.push({
          type: "min",
          value: 50,
          message: errorMin50,
        });
        rules.push({
          type: "max",
          value: 7500,
          message: errorMax7500,
        });
      }
      else if (item.type === "number") { // (one time) number inputs had min 100 and max 999,999,999
        rules.push({
          type: "min",
          value: 100,
          message: errorMin100,
        });
        rules.push({
          type: "max",
          value: 999999999,
          message: errorMax,
        });
      }
      return rules;
    };

    const instructionsArray = [t("FormInstructions.min100"), t("FormInstructions.min50max7500")];

    return (
      <ToggleTabItem>
        {items?.map((item, index) => {
          const currCompType = (
            ["text", "password", "email", "number"] as const
          ).includes(item.type as "text" | "password" | "email" | "number")
            ? "Input"
            : item.type;
          const compType = `Label${currCompType
            .replace(/^paragraph--/, "")
            .split("_")
            .map((word: string) => capitalizeFirstLetter(word))
            .join("")}`;
          const FormBit = loaderFormBit(compType);
          if (!FormBit) return null;
          return (
            <Suspense
              key={`compType-${outerIndex}-${index}`}
              fallback={<div>Loading...</div>}
            >
              <FormBitWrapper
                tabIndex={outerIndex}
                component={FormBit}
                name={item?.name}
                type={item?.type}
                validation={getValidationRules(item)}
                instructions={instructionsArray[outerIndex]}
                {...(typeof item === "object"
                  ? (({ name, type, ...rest }) => rest)(item)
                  : {})}
              />
            </Suspense>
          );
        })}
      </ToggleTabItem>
    );
  }
);

ToggleTabContent.displayName = "ToggleTabContent";

export default ToggleTabContent;
