"use client";

import React, { ReactNode, useState } from "react";
import {
  CloseButton,
  StyledTooltip,
  StyledTooltipContent,
  StyledTooltipIcon,
  TooltipText,
} from "./styles";

const IconWithTooltip = ({
  icon,
  tooltipText,
  className,
  placement = "top-start",
}: {
  icon: ReactNode;
  tooltipText: string;
  className?: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}) => {
  const [showTooltip, setShowTooltip] = useState(true);
  const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;

  const closeTooltip = () => {
    setShowTooltip(false);
    setTimeout(() => setShowTooltip(true), 0); // Reset state to allow re-triggering
  };
  return (
    <>
      <StyledTooltipIcon data-tooltip-id={tooltipId} className={className}>
        {icon}
      </StyledTooltipIcon>
      {showTooltip && (
        <StyledTooltip
          id={tooltipId}
          className="tooltip-style"
          place={placement}
          clickable
        >
          <StyledTooltipContent>
            <CloseButton onClick={closeTooltip}>×</CloseButton>
            <TooltipText dangerouslySetInnerHTML={{ __html: tooltipText }}/>
          </StyledTooltipContent>
        </StyledTooltip>
      )}
    </>
  );
};
export default IconWithTooltip;
