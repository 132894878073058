'use client'
import { Container, styled } from "@mui/material";
import theme from "@/theme/theme";

export const ContentFloodWrapper = styled(Container)(() => ({
  paddingLeft: theme.sizes.doublePadding, 
  paddingRight: theme.sizes.doublePadding, 
  [`${theme.mediaQueries.desktopM}`]: {
    ".MuiButton-root": {
      marginTop: theme.sizes.actionBtnsBottom
    },
    [`${theme.mediaQueries.tablet}`]: {
      ".MuiButton-root": {
          marginTop: theme.sizes.actionBtnsBottom
      },
    },
  },
  ".item-wrapper": {
    flexFlow: "row nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    gap: theme.sizes.cardsGap,
    paddingBottom: theme.sizes.cardsGap,
    justifyContent: "flex-start",
    [`${theme.mediaQueries.desktopM}`]: {
      justifyContent: "space-between",
      gap: theme.sizes.desktop.cardsGap,
      paddingBottom: theme.sizes.desktop.cardsGap,
    }
  },
}));

