"use client";

import { Typography } from "@mui/material";
import React, { useState } from "react";
import { ArrowLeftIcon } from '../reusable/IconsComponents';
import SearchResultItem from "./SearchResultItem";
import { StyledSearchGroupCollapseItem, StyledSearchGroupItem, StyledSearchGroupItemTitles } from "./styles";
import { SearchGroupItemProps } from "./types";
import { useTranslations } from "next-intl";

const SearchGroupItem: React.FC<SearchGroupItemProps> = ({ data, index, onPopupOpen }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const t = useTranslations("Nabat");
  const searchGroupItemCollapseTitle = t("searchGroupItemCollapseTitle");
  
  if(!data) return null;
  const { results, titles } = data;

  return (
        <StyledSearchGroupItem key={index}>
            {titles && (
              <StyledSearchGroupItemTitles onClick={() => setOpenCollapse(prev => !prev)}>
                <div className="search-title-group">
                  {titles[0]?.title && <Typography variant="h6">{titles[0]?.title}</Typography>}
                  {titles[0]?.subTitle && <Typography variant="h5">{titles[0]?.subTitle}</Typography>}
                </div>
                <div className="search-title-group">
                  {titles[0]?.payment && <Typography variant="h6">{'\u202A' + titles[0]?.payment + '\u202C'}</Typography>}
                  {titles[0]?.paymentDisc && <Typography variant="h5">{titles[0]?.paymentDisc}</Typography>}
                </div>
                <Typography className="search-group-collapse-title" variant="body1">{searchGroupItemCollapseTitle}<ArrowLeftIcon className={openCollapse ? 'open' : ''}/></Typography>
              </StyledSearchGroupItemTitles>
            )
            }
            <StyledSearchGroupCollapseItem in={openCollapse} timeout="auto" unmountOnExit>
                {results && results?.length > 0 &&
                      results.map(({ tagFlag, items, itemInfo }, innerIndex) => {
                        if (Array.isArray(items) && items.length > 0) {
                          return (
                            <SearchResultItem
                              key={`search-result-item${innerIndex}`}
                              tagFlag={tagFlag ? Number(tagFlag) : undefined}
                              items={items}
                              index={innerIndex}
                              onPopupOpen={() => onPopupOpen(itemInfo ? itemInfo : {})}
                            />
                          );
                        }
                        return null;
                })}
            </StyledSearchGroupCollapseItem>
        </StyledSearchGroupItem>

  );
};

export default React.memo(SearchGroupItem);
