'use client'

import { styled } from "@mui/material/styles";
import { Box, Button, Container } from "@mui/material";
import { StyledBoxProps } from "./types";
import theme from "@/theme/theme";

// TODO: move all hard coded sizes to the sizes file
export const ClockContainer = styled(Container)({
  position: "relative",
  display: "flex",
  overflow: "visible", // turn hidden after dev
  height: "280px",
  width: "auto",
  "&:focus": {
    outline: "none",
  },
  [`${theme.mediaQueries.tablet}`]: {
    height: "560px",
  },
  [`${theme.mediaQueries.desktopM}`]: {
    height: "560px",
  }
});

export const ClockItemsContainer = styled(Box)({
  position: "relative",
  display:"flex",
  justifyContent: "center",
  width: "200px",
  height: "200px",
  top: 0,
  right: "-20%",
  [`${theme.mediaQueries.tablet}`]: {
    top: "revert-layer",
    right: "-20%",
    width: "500px",
    height: "500px",
  },
  [`${theme.mediaQueries.desktopM}`]: {
    top: "38px",
    right: "5px",
    left: "revert-layer",
    width: "500px",
    height: "500px",
  },
  [`${theme.mediaQueries.desktop}`]: {
    top: "38px",
    right: "-43px",
    left: "revert-layer",
    width: "500px",
    height: "500px",
  }
});

export const ClockItem = styled(Box, {
          shouldForwardProp: (prop) => prop !== 'angle' && prop !== 'isVisible' && prop !== 'zIndex',
        })<StyledBoxProps>(({ angle, isVisible, zIndex }) => ({
  position: "absolute",
  width: 118,
  height: 154,
  backgroundColor: theme.colors.white,
  borderRadius: theme.sizes.radius.large,
  boxShadow: theme.colors.dropShadow.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
  fontWeight: "bold",
  transform: `rotate(${angle}deg) translateY(-170px)`,
  opacity: isVisible ? 1 : 0,
  visibility: isVisible ? "visible" : "hidden",
  zIndex: zIndex,
  left: "0",
  top: "150px",
  transition: "all 0.5s ease-in-out",
  [`${theme.mediaQueries.tablet}`]: {
    left: "50%",
    top: "50%",
    transform: `rotate(${angle}deg) translateY(-270px)`,
    width: 216,
    height: 280,
    fontSize: 24,
  },
  [`${theme.mediaQueries.desktopM}`]: {
    left: "50%",
    top: "50%",
    transform: `rotate(${angle}deg) translateY(-270px)`,
    width: 216,
    height: 280,
    fontSize: 24,
  }
}));

export const RotateButton = styled(Button)({
  "&.circle": {
    width: 26,
    height: 26,
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    borderRadius: "50%",
    padding: theme.sizes.basePadding,
    zIndex: 100,
    boxShadow: theme.colors.dropShadow.main,
    [`${theme.mediaQueries.tablet}`]: {
      width: 50,
      height: 50,
      padding: theme.sizes.gap,
    },
    [`${theme.mediaQueries.desktopM}`]: {
      width: 50,
      height: 50,
      padding: theme.sizes.gap,
    },
    "&.left": {
      top: "127px",
      right: "-24%",
      transform: "rotate(-30deg)",
      [`${theme.mediaQueries.tablet}`]: {
        top: "122px",
        right: "-12%",
        left: "unset",
        transform: "rotate(0)",
      },
      [`${theme.mediaQueries.desktopM}`]: {
        top: "180px",
        right: "7%",
        left: "unset",
        transform: "rotate(0)",
      },
      [`${theme.mediaQueries.desktop}`]: {
        right: 0,
      },
      [`${theme.mediaQueries.desktopXl}`]: {
        right: 0,
      }
    },
    "&.right": {
      top: "127px",
      left: "-25%",
      transform: "rotate(30deg)",
      [`${theme.mediaQueries.tablet}`]: {
        top: "unset",
        bottom: "140px",
        left: "unset",
        right: "60%",
        transform: "rotate(55deg)",
      },
      [`${theme.mediaQueries.desktopM}`]: {
        top: "unset",
        bottom: "85px",
        left: "unset",
        right: "85%",
        transform: "rotate(55deg)",
      },
      [`${theme.mediaQueries.desktop}`]: {
        right: "400px",
      },
      [`${theme.mediaQueries.desktopXl}`]: {
        right: "400px",
      }
    }, 
    "&:focus": {
      outline: "none",
    },
    '&:active': {
      border: `1px solid ${theme.colors.mainRed}`,
      color: theme.colors.white,
      background: theme.colors.mainRed,
      "svg": {
        stroke: theme.colors.white,
        fill: theme.colors.white,
      },
      outline: "none",
    },
    "svg": {
      fontSize: 8,
      stroke: theme.colors.mainRed,
      strokeWidth: "3px",
      [`${theme.mediaQueries.tablet}`]: {
        fontSize: 16,
        strokeWidth: "3px"
      },
      [`${theme.mediaQueries.desktopM}`]: {
        fontSize: 16,
        strokeWidth: "3px"
      }
    }
  },
});
