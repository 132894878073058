"use client";

import React from "react";
import BubblesLinkListComponent from "@/components/BubblesLinkListComponent/BubblesLinkListComponent";
import SearchComponent from "@/components/SearchComponent/SearchComponent";

interface SearchLinksProps {
  data: {
    text: string;
    href: string;
  }[];
}

const SearchLinks: React.FC<SearchLinksProps> = ({ data }) => {
  return (
    <>
      <SearchComponent />
      <BubblesLinkListComponent data={data} />
    </>
  );
};

export default SearchLinks;
