"use client";
import theme from "@/theme/theme";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Tooltip } from "react-tooltip";

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  box-shadow: ${theme.colors.dropShadow.mainSmall};
  z-index: 10;
  font-size: unset;
  font-weight: unset;
  white-space: normal;
  &.tooltip-style {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
    background: ${theme.colors.greyCold};
    color: ${theme.colors.mainGrey};
    opacity: 1;
    max-width: 95%;
    padding: ${theme.sizes.tripleMargin} ${theme.sizes.doubleMargin} ${theme.sizes.doubleMargin} ${theme.sizes.doubleMargin};
    font-family: var(--fonts-next);
    ${theme.mediaQueries.desktopM} {
      max-width: 300px;
    }
  }
  ${theme.mediaQueries.desktopM} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const StyledTooltipContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const TooltipText = styled.span`
  font-size: 14px;
  font-weight: initial;
`;
export const StyledTooltipIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  flex: 0 0 auto;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  color: ${theme.colors.mainGrey};
  cursor: pointer;
  margin-left: auto;
  font-size: 24px;
  stroke-width: 0.5px;
  &:hover {
    color: ${theme.colors.black};
  }
`;
