"use client";

import React, { useEffect, useState } from "react";
import { FormHelperText } from "@mui/material";

import { ValidationState } from "../types";
import { LabelInputProps } from "./types";
import { formatNumberWithCommas, unformatNumber } from "@/lib/utils/stringMethods";
import { StyledFormControl, StyledInputLabel, StyledSubtitle } from "../styles";
import { StyledInput } from "./styles";

const LabelInput: React.FC<LabelInputProps> = ({
  label,
  subtitle,
  type,
  defaultValue,
  instructions,
  value,
  onChange,
  validation = [],
}) => {
  const [error, setError] = useState<string | undefined>();
  const [displayValue, setDisplayValue] = useState<string>(value?.toString() || defaultValue?.toString() || "");
  
  const validate = (currentValue: any): ValidationState => {
    // For number inputs, validate the unformatted value
    const valueToValidate = type === "number" ? unformatNumber(String(currentValue), type) : currentValue;
    
    for (const rule of validation) {
      switch (rule.type) {
        case "required":
          if (!valueToValidate) return { isValid: false, error: rule.message };
          break;
        case "min":
          if (Number(valueToValidate) < Number(rule.value))
            return { isValid: false, error: rule.message };
          break;
        case "max":
          if (Number(valueToValidate) > Number(rule.value))
            return { isValid: false, error: rule.message };
          break;
        case "pattern":
          if (!String(valueToValidate).match(rule.value as RegExp))
            return { isValid: false, error: rule.message };
          break;
        case "custom":
          if (rule.validator && !rule.validator(valueToValidate))
            return { isValid: false, error: rule.message };
          break;
      }
    }
    return { isValid: true };
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    
    // For number inputs, we need to handle the formatting
    if (type === "number") {
      // Remove non-numeric chars except commas for validation
      const unformattedValue = unformatNumber(inputValue, type);
      
      // Only proceed if the input is empty or a valid number
      if (unformattedValue === "" || /^\d*$/.test(unformattedValue)) {
        const formattedValue = formatNumberWithCommas(unformattedValue, type);
        setDisplayValue(formattedValue);
        
        const validationState = validate(unformattedValue);
        setError(validationState.error);
        onChange(unformattedValue, validationState.isValid);
      }
    } else {
      // For non-number types, handle normally
      setDisplayValue(inputValue);
      const validationState = validate(inputValue);
      setError(validationState.error);
      onChange(inputValue, validationState.isValid);
    }
  };
  
  useEffect(() => {
    if (defaultValue) {
      const formatted = formatNumberWithCommas(defaultValue, type);
      setDisplayValue(formatted);
      const validationState = validate(defaultValue);
      onChange(type === "number" ? unformatNumber(formatted, type) : formatted, validationState.isValid);
    }
  }, []);
  
  // Update display value when external value changes
  useEffect(() => {
    if (value !== undefined) {
      setDisplayValue(formatNumberWithCommas(value, type));
    }
  }, [value]);
  
  return (
    <StyledFormControl error={!!error}>
      {label && <StyledSubtitle variant="body2">{label}</StyledSubtitle>}
      {subtitle && <StyledInputLabel>{subtitle}</StyledInputLabel>}
      <StyledInput
        value={displayValue}
        className={type}
        onChange={handleChange}
        type={type === "number" ? "text" : type}
        inputProps={{
          inputMode: type === "number" ? "numeric" : undefined, // Ensures a numeric keyboard on mobile
          pattern: type === "number" ? "[0-9,]*" : undefined, // Allow numbers and commas
        }}
        error={!!error}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : instructions && <StyledInputLabel>{instructions}</StyledInputLabel>}
    </StyledFormControl>
  );
};

export default React.memo(LabelInput);
