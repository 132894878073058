"use client";
import theme from "@/theme/theme";
import { Box, styled, Typography } from "@mui/material";

export const MiniContentWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${theme.sizes.gap};
  ${theme.mediaQueries.desktopM} {
  }
`;

export const MiniTitle = styled(Typography)`
  color: ${theme.colors.mainRed};
  font-size: ${theme.sizes.fonts.desktop.content};
  font-weight: 700;
  margin-top: ${theme.sizes.doubleMargin};
  margin: 0;
  ${theme.mediaQueries.desktopM} {
  }
`;

export const MiniText = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: ${theme.sizes.fonts.desktop.subContent};
  h4 {
    margin: 0;
  }
  .text {
    font-weight: normal;
  }
  ${theme.mediaQueries.desktopM} {
  }
`;

export const MiniList = styled("ul")`
  width: 100%;
  padding: 0;
  list-style: none;
  max-height: 202px;
  margin: 0;
  overflow-y: auto;
  ${theme.mediaQueries.desktopM} {
    max-height: 300px;
  }
  li {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    position: relative;
    &:before {
      margin-top: 0;
      content: "•";
      color: ${theme.colors.mainGrey};
    }
  }
`;

export const MiniCollapseWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const MiniCollapseTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-weight: 700;
  gap: ${theme.sizes.halfBasePadding};
  h6 {
    font-size: ${theme.sizes.fonts.megaMenu};
    &.open {
      p: {
        font-weight: 700;
      }
    }
  }
  svg {
    fill: ${theme.colors.mainGrey};
    height: ${theme.sizes.icon.xXSmall};
    transition: all 0.2s ease-in-out;
    transform: rotate(90deg);
    &.open {
      transform: rotate(-90deg);
    }
  }
`;

export const MiniCollapseItem = styled(Box)`
  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    max-height: 300px;
    overflow-y: auto;
    gap: ${theme.sizes.gap};
    padding: ${theme.sizes.doublePadding} 0;
  }
`;

export const MiniTable = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.sizes.doubleMargin};
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.mainBlue} transparent;
  ${theme.mediaQueries.desktopM} {
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.mainBlue};
    border-radius: 10px;
    border: 2px solid ${theme.colors.greySilver};
  }
`;

export const MiniTableItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasDisclaimer'
})<{ hasDisclaimer?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  padding: ${theme.sizes.doublePadding};
  gap: ${theme.sizes.tripleMargin};
  border: 1px solid ${theme.colors.greySilver};
  border-radius: ${theme.sizes.radius.large};
  ${theme.mediaQueries.desktopM} {
    flex-flow: ${props => props.hasDisclaimer ? 'row wrap' : 'row nowrap'};
    justify-content: flex-start;
  }
`;

export const MiniTableItemInner = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: ${theme.sizes.halfBasePadding};
  border-inline-end: none;
  border-bottom: 1px solid ${theme.colors.greySilver};
  padding-inline-end: ${theme.sizes.tripleMargin};
  ${theme.mediaQueries.desktopM} {
    flex-direction: column;
    justify-content: flex-start;
    width: unset;
    border-inline-end: 1px solid ${theme.colors.greySilver};
    border-bottom: none;
    &:first-child {
      width: auto;
    }
  }
  &:last-child {
    border-inline-end: none;
    border-bottom: none;
    padding-inline-end: 0;
    &.disclaimer {
      width: 100%;
    }
  }
  h4 {
    font-size: ${theme.sizes.fonts.desktop.subContent};
    width: 55%;
    ${theme.mediaQueries.desktopM} {
      width: unset;
    }
  }
  h5 {
    font-size: ${theme.sizes.fonts.desktop.content};
  }
`;
