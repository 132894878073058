"use client";
import React, { useState } from "react";
import { IconImageCardProps } from "./types";
import { StyledCard } from "./styles";
import { Button, Typography } from "@mui/material";
import Image from "next/image";
import ArrowLink, { ArrowLinkButton } from "../reusable/ArrowLink/ArrowLink";
import { ArrowLeftIcon, Icons } from "../reusable/IconsComponents";
import { CloseButton } from "../reusable/ActionButton/ActionButton";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { toPascalCase } from "@/lib/utils/stringMethods";

const IconImageCard: React.FC<IconImageCardProps> = ({
  width,
  height,
  widthDesktop,
  heightDesktop,
  subtitle,
  titleLink,
  title,
  bigTitle,
  text,
  image,
  icon,
  link,
  subitemsText,
  subitems,
  className,
  variant,
  button,
  animationStyle,
  GTMEventName = "card",
}) => {
  const [showList, setShowList] = useState(false);
  const handleSwitchContent = () => {
    setShowList((prev) => !prev);
  };
  let ImportedIcon;
  if (icon)
    ImportedIcon = Icons[toPascalCase(icon) as keyof typeof Icons] || null; // import icon dynamically

  return (
    <StyledCard
      width={width}
      height={height}
      widthDesktop={widthDesktop}
      heightDesktop={heightDesktop}
      variant={variant}
      className={className}
      style={animationStyle ? animationStyle : {}}
    >
      {image && <Image {...image} />}
      <div className="content">
        {ImportedIcon && <ImportedIcon />}
        {title && (
          <Typography fontWeight={"bold"} className="title" variant="h4">
            {title}
          </Typography>
        )}
        {bigTitle ||
          (titleLink && (
            <Typography
              fontWeight={"normal"}
              className="title"
              variant="h3"
              onClick={() => {
                handleSwitchContent();
                handleGTMEvent(
                  GTMEventName,
                  `${GTMEventName}-${titleLink.text}`,
                  {
                    item_url: titleLink?.href ? titleLink.href : "",
                    item_category: GTMEventName,
                    item_text: titleLink.text,
                  }
                );
              }}
            >
              {bigTitle ? bigTitle : titleLink.text}
            </Typography>
          ))}
        {subtitle && (
          <Typography className="subtitle" variant="h4">
            {subtitle}
          </Typography>
        )}
        {text && <Typography className="text">{text}</Typography>}
        {!subitems && !button && link && (
          <ArrowLink
            href={link.href}
            id={`${GTMEventName}-arrow-link-${link.text}`}
            className={className}
            noTextMobile={className?.includes("flat-mobile") ? true : false}
            text={link.text}
            onClick={() =>
              handleGTMEvent(
                GTMEventName,
                `${GTMEventName}-arrow-link-${link.text}`,
                {
                  item_url: link?.href ? link.href : "",
                  item_category: GTMEventName,
                  item_text: link.text,
                }
              )
            }
          />
        )}
        {!link && button && (
          <Button
            className="circle"
            id={`${GTMEventName}-button-${button.text}`}
            variant="elevated"
            onClick={() =>
              handleGTMEvent(
                GTMEventName,
                `${GTMEventName}-button-${button.text}`,
                {
                  item_url: button?.href ? button.href : "",
                  item_category: GTMEventName,
                  item_text: bigTitle ? bigTitle : titleLink?.text,
                }
              )
            }
            {...button}
          >
            <ArrowLeftIcon />
          </Button>
        )}
        {subitems && (
          <>
            <ArrowLinkButton
              variant={"ghost"}
              className="more-content"
              text={subitemsText}
              {...button}
              onClick={handleSwitchContent}
            />
            <div className={`link-list ${showList ? "show" : ""}`}>
              <CloseButton
                variant={"ghost"}
                className="circle"
                onClick={handleSwitchContent}
              />
              {subitems.map((item, index) => {
                const link = item?.link;
                if (link)
                  return (
                    <a
                      className="card-list-item"
                      id={`${GTMEventName}-card-list-item-${link.text}`}
                      key={link.text + index}
                      href={link.href ? String(link.href) : ""}
                      onClick={() =>
                        handleGTMEvent(
                          GTMEventName,
                          `${GTMEventName}-card-list-item-${link.text}`,
                          {
                            item_url: link?.href ? link.href : "",
                            item_category: GTMEventName,
                            item_text: link.text,
                          }
                        )
                      }
                    >
                      {link.text}
                    </a>
                  );
              })}
              {link && (
                <ArrowLink
                  className="outer-link"
                  id={`${GTMEventName}-outer-link-${link.text}`}
                  href={link.href}
                  text={link.text}
                  onClick={() =>
                    handleGTMEvent(
                      GTMEventName,
                      `${GTMEventName}-outer-link-${link.text}`,
                      {
                        item_url: link?.href ? link.href : "",
                        item_category: GTMEventName,
                        item_text: link.text,
                      }
                    )
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    </StyledCard>
  );
};

export default IconImageCard;
