"use client";

import { styled } from "@mui/material/styles";
import theme, { imageUrl } from "@/theme/theme";
import { Box } from "@mui/material";
import { StyledLeadItemProps } from "./types";

export const LeadComponentWrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: theme.sizes.gap,
  height: "fit-content",
  width: "100%",
  padding: `${theme.sizes.tripleMargin} ${theme.sizes.doubleMargin}`,
  zIndex: "10",
  [`${theme.mediaQueries.desktopM}`]: {
    padding: theme.sizes.octoMargin,
    paddingLeft: "10%",
  },
}));

export const LeadItemsWrapper = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridAutoFlow: "row",
  gap: theme.sizes.leadComp.tablet.gap,
  width: "100%",
  height: "fit-content",
  [`${theme.mediaQueries.desktopM}`]: {
    height: "673px",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridAutoFlow: "column",
    gap: theme.sizes.leadComp.gap,
    marginLeft: "15%",
  },
  [`${theme.mediaQueries.desktop}`]: {
    marginLeft: "14%",
  },
  [`${theme.mediaQueries.desktopXl}`]: {
    marginLeft: "9%",
  },
}));

export const StyledLeadItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "index" && prop !== "index",
})<StyledLeadItemProps>(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: theme.sizes.leadComp.tablet.cardWidth,
  height: theme.sizes.leadComp.tablet.cardHeight,
  borderRadius: theme.sizes.radius.large,
  boxShadow: theme.colors.dropShadow.main,
  background: "white",
  overflow: "hidden",
  img: {
    height: "unset",
    width: "100%",
    marginTop: "auto",
    marginInlineEnd: "unset",
    zIndex: 1,
    [`${theme.mediaQueries.desktopM}`]: {
      height: "auto",
      marginInlineEnd: "auto",
    },
  },
  ".title-wrapper": {
    padding: theme.sizes.leadComp.tablet.titleWrapperPadding,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    zIndex: 2,
    [`${theme.mediaQueries.desktopM}`]: {
      padding: theme.sizes.leadComp.titleWrapperPadding,
    },
    ".title": {
      fontSize: theme.sizes.leadComp.tablet.titles.title,
      lineHeight: 1,
      marginBottom: theme.sizes.baseMargin,
      [`${theme.mediaQueries.desktopM}`]: {
        lineHeight: 1.3,
        fontSize: theme.sizes.leadComp.titles.title,
        marginBottom: 0,
      },
    },
    ".sub_title": {
      fontSize: theme.sizes.leadComp.tablet.titles.sub_title,
      lineHeight: 1.2,
      [`${theme.mediaQueries.desktopM}`]: {
        fontSize: theme.sizes.leadComp.titles.sub_title,
      },
    },
    ".black_title": {
      fontSize: theme.sizes.leadComp.tablet.titles.black_title,
      [`${theme.mediaQueries.desktopM}`]: {
        fontSize: theme.sizes.leadComp.titles.black_title,
      },
    },
    ".red_title": {
      fontSize: theme.sizes.leadComp.tablet.titles.red_title,
      lineHeight: "0.8",
      [`${theme.mediaQueries.desktopM}`]: {
        fontSize: theme.sizes.leadComp.titles.red_title,
      },
    },
  },
  h2: {
    "&:before": {
      content: "none",
    },
  },
  "&.red-card": {
    backgroundColor: theme.colors.mainRed,
    color: theme.colors.white,
    ".red_title": {
      color: theme.colors.white,
      background: "unset",
      WebkitTextFillColor: "unset",
    },
  },
  "&.long-card": {
    display: "flex",
    flexDirection: "column",
  },
  "&.wide-card": {
    display: "flex",
    flexDirection: "column",
    width: theme.sizes.leadComp.tablet.cardWidth,
    justifyContent: "flex-start",
    ".title-wrapper": {
      textAlign: "left",
    },
    img: {
      width: "auto",
      height: "100%",
      marginInlineEnd: "auto",
      zIndex: 1,
    },
  },
  "&.box-0": {
    order: 1,
    height: "339px",
    gridColumnStart: "span 1",
    gridRowStart: "span 2",
    ".title-wrapper": {
      marginBottom: "-16px",
      padding: "20px",
      paddingTop: theme.sizes.desktop.containerPadding,
      paddingRight: "29px",
      paddingLeft: "29px",
      [`${theme.mediaQueries.desktopM}`]: {
        paddingBottom: 0,
        paddingRight: "43px",
        paddingLeft: "43px",
        marginBottom: "-16px",
      },
    },
  },
  "&.box-1": {
    // specific override according to design
    order: 2,
    height: "145px",
    gridColumnStart: "span 1",
    gridRowStart: "span 1",
    [`${theme.mediaQueries.desktopM}`]: {
      ".title-wrapper": {
        ".black_title": {
          fontSize: "20px",
          fontWeight: "normal",
        },
        ".red_title": {
          lineHeight: 1,
        },
      },
    },
  },
  "&.box-2": {
    // specific override according to design
    order: 4,
    height: "206px",
    gridColumnStart: "span 1",
    gridRowStart: "span 1",
    "&:not(.red-card)": {
      backgroundImage: `url("${imageUrl}images/homepage/Lead/thanks.png")`,
      backgroundRepeat: "no-repeat" /* Ensure the image does not repeat */,
      backgroundPosition:
        "left bottom" /* Position the image at the bottom-left */,
    },
    ".title-wrapper": {
      alignItems: "center",
      textAlign: "center",
      ".red_title": {
        fontSize: "40px",
      },
      [`${theme.mediaQueries.desktopM}`]: {
        alignItems: "flex-start",
        ".red_title": {
          fontSize: "68px",
        },
      },
    },
  },
  "&.box-3": {
    // specific override according to design
    order: 5,
    height: "306px",
    gridColumnStart: "span 1",
    gridRowStart: "span 2",
    "&:not(.red-card)": {
      backgroundImage: `url("${imageUrl}images/homepage/Lead/polly.png")`,
      backgroundRepeat: "no-repeat" /* Ensure the image does not repeat */,
      backgroundPosition:
        "left bottom" /* Position the image at the bottom-left */,
    },
  },
  "&.box-4": {
    // specific override according to design
    order: 3,
    height: "176px",
    gridColumnStart: "span 1",
    gridRowStart: "span 1",
  },
  "&.box-5": {
    // specific override according to design
    order: 6,
    height: "263px",
    gridColumnStart: "span 1",
    gridRowStart: "span 1",
    ".title-wrapper": {
      marginTop: theme.sizes.containerPadding,
      [`${theme.mediaQueries.desktopM}`]: {
        marginTop: "unset",
      },
    },
  },
  [`${theme.mediaQueries.desktopM}`]: {
    width: theme.sizes.leadComp.cardWidth,
    height: theme.sizes.leadComp.cardHeight,
    "&.box-0, &.box-1, &.box-2, &.box-3, &.box-4, &.box-5": {
      order: "unset",
      height: "revert-layer",
    },
    "&.long-card": {
      width: theme.sizes.leadComp.cardWidth,
      height: theme.sizes.leadComp.cardLargeHeight,
      gridRowStart: "span 2",
    },
    "&.wide-card": {
      flexDirection: "row",
      width: theme.sizes.leadComp.cardLargeWidth,
      height: theme.sizes.leadComp.cardHeight,
      gridColumnStart: "span 2",
      gridRowStart: "span 1",
    },
  },
}));
