import { DrupalClient } from "next-drupal";
import fetch from "cross-fetch";

async function customFetcher(
  url: any,
  options = {},
  retries = 3,
  backoff = 500
) {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error: any) {
    if (retries > 0) {
      console.warn(
        `Fetch failed: ${error.message || ""}. Retrying in ${backoff}ms...`
      );
      await new Promise((resolve) => setTimeout(resolve, backoff));
      return customFetcher(url, options, retries - 1, backoff * 2);
    } else {
      console.error("Max retries reached. Fetch failed:", error);
      throw error;
    }
  }
}

// drupal client config
export const drupal = new DrupalClient(
  process.env.NEXT_PUBLIC_DRUPAL_BASE_URL
    ? process.env.NEXT_PUBLIC_DRUPAL_BASE_URL
    : "",
  { fetcher: customFetcher }
);
