"use client";

import { Input } from "@mui/material";
import theme from "@/theme/theme";
import styled from "@emotion/styled";

export const StyledInput = styled(Input)`
  position: relative;
  margin-top: 0;
  width: 90%;
  // Hide the spinner arrows for number inputs
  & input[type="number"]::-webkit-outer-spin-button,
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type="number"] {
    -moz-appearance: textfield;
  }
`;
