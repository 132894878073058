"use client";

import React, { useState } from "react";
import { ToggleButton } from "@mui/material";
import { ToggleTabsWrapperProps } from "./types";
import {
  StyledSubtitle,
  StyledToggleButtonGroup,
  StyledToggleTabsWrapper,
} from "./styles";

const ToggleTabsWrapper: React.FC<ToggleTabsWrapperProps> = ({
  label,
  tabs,
  children,
  setActiveTabIndex,
}) => {
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newTab: string | null
  ) => {
    if (newTab !== null) {
      setCurrentTab(newTab);
      const tabIndex = tabs.findIndex((tab) => tab.value === newTab);
      setActiveTabIndex(tabIndex);
    }
  };
  return (
    <StyledToggleTabsWrapper>
      {label && <StyledSubtitle variant="body2">{label}</StyledSubtitle>}
      <StyledToggleButtonGroup
        value={currentTab}
        exclusive
        onChange={handleToggle}
        aria-label="Tab Toggle"
        className="mb-4"
      >
        {tabs.map((tab) => (
          <ToggleButton
            key={tab.value}
            value={tab.value}
            aria-label={tab.label}
          >
            {tab.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <div>
        {Array.isArray(children) &&
          children.map((child, index) =>
            tabs[index]?.value === currentTab ? (
              <div key={tabs[index].value} className="tab-content">
                {child}
              </div>
            ) : null
          )}
      </div>
    </StyledToggleTabsWrapper>
  );
};

export default ToggleTabsWrapper;
