'use client'
import { Box, styled } from "@mui/material";
import { StyledBoxProps } from "./types";
import theme from "@/theme/theme";

export const StyledCard = styled(Box, {
      shouldForwardProp: (prop) =>
        prop !== 'width' &&
        prop !== 'height' &&
        prop !== 'widthDesktop' &&
        prop !== 'heightDesktop' &&
        prop !== 'variant',
    })<StyledBoxProps>(({width, height, widthDesktop, heightDesktop, variant }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width:  width || 'auto',
    height: height || 'auto',
    boxSizing: "border-box",
    borderRadius: theme.sizes.radius.large,
    color: theme.colors.mainGrey,
    backgroundColor: theme.colors.white,
    boxShadow: variant !== "ghost" ? theme.colors.dropShadow.main : 'none',
    overflow: "hidden",
    flex: "0 0 auto",
    [`${theme.mediaQueries.desktopM}`]: {
        width:  widthDesktop || 'auto',
        height: heightDesktop || 'auto',
    },
    "> img": {
        width: "100%",
        height: "auto",
        marginBottom: "auto",
    },
    "&.icons": {
        "img": {
            height: theme.sizes.icon.xLarge,
            width: "auto",
            objectFit: "contain",
            flex: "0 0 auto",
            transition: "opacity 0.3s ease-in-out",
            marginTop: "auto",
            marginBottom: "8px",
            [`${theme.mediaQueries.desktopM}`]: {
                height: theme.sizes.icon.desktop.xLarge,
                marginBottom: "14px",
            },
        }
    },
    "&:not(.icons)": {
        ".content": {
            padding: `${theme.sizes.doubleMargin} 0`,
            [`${theme.mediaQueries.desktopM}`]: {
                padding: `0`,
            },
        }
    },
    "&.flat-mobile": {
        flexDirection: "row",
        boxShadow: theme.colors.dropShadow.main,
        padding: theme.sizes.basePadding,
        borderRadius: theme.sizes.radius.circle,
        [`${theme.mediaQueries.desktopM}`]: {
            boxShadow: variant !== "ghost" ? theme.colors.dropShadow.main : 'none',
            flexDirection: "column",
            borderRadius: 0,
        },
        "> img": {
            marginTop: "unset",
            paddingLeft: theme.sizes.basePadding,
            [`${theme.mediaQueries.desktopM}`]: {
                paddingLeft: "unset"
            }
        },
        ".content": {
            flexFlow: "column wrap",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "100%",
            paddingLeft: theme.sizes.doublePadding,
            "h4, p": {
                marginTop: "unset",
                fontSize: theme.sizes.fonts.body,
                textAlign: "start",
                width: "90%"
            },
            "a": {
                height: "100%",
                marginInlineStart: "auto",
                fontSize: theme.sizes.fonts.h4,
                paddingRight: theme.sizes.basePadding,
            },
            [`${theme.mediaQueries.desktopM}`]: {
                paddingLeft: "unset",
                alignItems: "center",
                "h4, p": {
                    fontSize: theme.sizes.fonts.desktop.h4,
                    textAlign: "center",
                    width: "unset"
                },
                "a": {
                    width: "100%",
                    height: "auto",
                    marginInlineStart: "unset",
                    fontSize: theme.sizes.fonts.desktop.h4,
                    paddingRight: "unset",
                    justifyContent: "center"
                },
            },
        }
    },
    ".content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        "&:last-child": {
            marginBottom: "auto"
        },
        "h4:not(.title)": {
            marginTop: 0,
            fontWeight: "normal"
        },
        ".title": {
            cursor : "pointer",
        },
        "a": {
            textDecoration: "none",
            fontSize: theme.sizes.fonts.body,
            [`${theme.mediaQueries.desktopM}`]: {
                fontSize: theme.sizes.fonts.desktop.body,
            },
        },
        "button": {
            marginTop: theme.sizes.baseMargin,
            fontSize: theme.sizes.fonts.body,
            [`${theme.mediaQueries.desktopM}`]: {
                fontSize: theme.sizes.fonts.desktop.body,
            },
        },
        ".circle": {
            "svg": {
                strokeWidth: "2px",
                width: "14px",
            },
            "&.MuiButton-elevated": {
                marginTop: theme.sizes.baseMargin,
                marginBottom: "0",
                "svg": {
                    stroke: theme.colors.mainRed
                }, 
                [`${theme.mediaQueries.desktopM}`]: {
                    marginTop: theme.sizes.doubleMargin,
                    fontSize: theme.sizes.fonts.desktop.body,
                },
            },
        },
        ".text": {
            textAlign: 'center',
            fontSize: theme.sizes.fonts.body,
            [`${theme.mediaQueries.desktopM}`]: {
                fontSize: theme.sizes.fonts.desktop.body,
            },
        },
        "> svg": {
            height: theme.sizes.icon.large,
            width: "auto",
            flex: "0 0 auto",
        },
        ".link-list": {
            position: "absolute",
            display: "flex",
            flexFlow: "column wrap",
            justifyContent: "space-around",
            width: "100%",
            height: height || 'auto',
            top: 0,
            lineHeight: 1,
            padding: "30px 8px 40px",
            backgroundColor: theme.colors.white,
            border: `2px solid ${theme.colors.mainRed}`,
            borderRadius: theme.sizes.radius.large,
            transition: "opacity 0.3s ease-in-out",
            opacity: 0,
            pointerEvents: "none",
            [`${theme.mediaQueries.desktopM}`]: {
                justifyContent: "space-around",
                gap: theme.sizes.desktop.linksGap,
                padding: "30px 28px",
                height: heightDesktop || 'auto',
            },
            ".close-button": {
                display: "flex",
                position: "absolute",
                right: "4px",
                top: "-6px",
                background: "transparent",
                stroke: theme.colors.mainGrey,
                fill: theme.colors.mainGrey,
                [`${theme.mediaQueries.desktopM}`]: {
                    right: "15px",
                    top: "6px",
                },
            },
            ".card-list-item": {
                fontSize: theme.sizes.fonts.body,
                borderBottom: `1px solid ${theme.colors.mainGrey}`,
                [`${theme.mediaQueries.desktopM}`]: {
                    fontSize: theme.sizes.fonts.desktop.body,
                },
            },
            ".outer-link": {
                position: "absolute",
                display: "flex",
                bottom: "20px",
                right: "unset",
                left: "8px",
                [`${theme.mediaQueries.desktopM}`]: {
                    right: "24px",
                    bottom: "28px",
                    left: "unset",
                    },
                },
            "&.show" : {
                opacity: 1,
                pointerEvents: "all",
                
                
            },
        },
        ".more-content": {
            padding: "0"
        },
        ...(variant === "ghost" && {
            justifyContent: "flex-start",
            "> *": {
                marginTop: theme.sizes.doubleMargin,
            },
            "> a": {
                marginTop: "auto",
            },
        }),
        ...(variant === "big-image" && {
            height: "100%",
            "a": {
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                padding: `${theme.sizes.doublePadding}`,
                fontSize: theme.sizes.fonts.subContent,
                [`${theme.mediaQueries.desktopM}`]: {
                    fontSize: theme.sizes.fonts.desktop.subContent,
                },
                "svg": {
                    fontSize: theme.sizes.fonts.content,
                    marginInlineStart: "auto",
                    marginTop: "auto",
                    marginBottom: "auto",
                    flex: "0 0 auto",
                }
            }
        }),
    },
}));

export const CardsWrapper = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    flexFlow: "column",
    width: "100%",
    padding: 0,
    "> button, > a": {
        marginInlineStart: "auto",
        marginTop: theme.sizes.actionBtnsBottom,
        marginInlineEnd: theme.sizes.doublePadding,
        [`${theme.mediaQueries.desktopM}`]: {
            marginInlineEnd: 0,
            marginTop: theme.sizes.desktop.actionBtnsBottom,
        }
    },
    "h2": {
        marginBottom: theme.sizes.quadricMargin,
        marginInlineStart: theme.sizes.doublePadding,
        [`${theme.mediaQueries.desktopM}`]: {            
            marginInlineStart: 0,
        }
    },
    ".item-wrapper": {
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.sizes.gap,
        width: "100%",
        scrollbarColor: `${theme.colors.greySilver} ${theme.colors.white}`,
        scrollbarWidth: "thin",
    },
    [`${theme.mediaQueries.desktopM}`]: {
        ".item-wrapper": {
            justifyContent: "space-between",
        },
    }
}));