import React, { useEffect, useState } from "react";
import { drupal } from "@/lib/drupal";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"), { ssr: false });

interface LottieAnimationProps {
  src: string;
  loop?: boolean;
  autoplay?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  src,
  loop = true,
  autoplay = true,
  style,
  className,
}) => {
  const [animationData, setAnimationData] = useState<object | null>(null);

  useEffect(() => {
    const fetchAnimation = async () => {
      try {
        let response;
        if (
          drupal?.baseUrl === "http://localhost" ||
          drupal?.baseUrl === "https://localhost:3000"
        ) {
          const apiUrl = `/api/proxy?url=${encodeURIComponent(src)}`;
          response = await fetch(apiUrl);
        } else {
          response = await fetch(src);
        }
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Error loading Lottie animation:", error);
      }
    };

    fetchAnimation();
  }, [src]);

  return (
    <Lottie
      loop={loop}
      animationData={animationData || undefined}
      play={autoplay}
      style={style}
      className={className}
    />
  );
};

export default LottieAnimation;
