"use client";

import React, { useCallback } from "react";
import { useFormContext } from "./FormContext";
import { FormBitWrapperProps } from "./types";

const FormBitWrapper: React.FC<FormBitWrapperProps> = ({
  tabIndex,
  name,
  component: Component,
  validation = [],
  ...props
}) => {
  const { updateValue, updateValidationState, setIsValid } = useFormContext();

  const handleChange = useCallback(
    (value: any, isValid: boolean) => {
      updateValue(tabIndex, name, value);
      updateValidationState(tabIndex, name, isValid);
      setIsValid(isValid);
    },
    [tabIndex, name, updateValue, updateValidationState, setIsValid]
  );

  return (
    <Component {...props} validation={validation} onChange={handleChange} />
  );
};

export default FormBitWrapper;
