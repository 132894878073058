"use client";

import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { ArrowLeftIcon, InformationIcon } from "../reusable/IconsComponents";
import { useTranslations } from "next-intl";
import { SearchFiltersProps } from "./types";
import {
  StyledFiltersButtonGroup,
  StyledFiltersCollapse,
  StyledFiltersCollapseTitle,
} from "./styles";
import { useFormContext } from "../reusable/FormBits/FormContext";
import IconWithTooltip from "../reusable/IconWithTooltip/IconWithTooltip";

const SearchFilters: React.FC<SearchFiltersProps> = ({ filterOptions }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const t = useTranslations("Nabat");
  const arrowLinkLabel = t("arrowLinkLabel");
  const { setFilteredResults, results } = useFormContext();

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string | null;
  }>({});

  const handleFilterSelect = (category: string, value: string, key: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: prev[category] === key ? null : key,
    }));
  };

  useEffect(() => {
    if (!selectedFilters) {
      setFilteredResults(results);
    } else {
      const filtered = results.filter((result) => {
        return Object.entries(selectedFilters).every(([category, value]) => {
          if (!value) return true;
          return (
            result?.itemInfo?.filterItem[category] == value || value == "999" // 999 is a special value for "All"
          );
        });
      });
      setFilteredResults(filtered);
    }
  }, [selectedFilters]);

  return (
    <>
      <StyledFiltersCollapseTitle
        onClick={() => setOpenCollapse((prev) => !prev)}
      >
        <Typography variant="h5">
          {arrowLinkLabel}
          <ArrowLeftIcon className={openCollapse ? "open" : ""} />
        </Typography>
      </StyledFiltersCollapseTitle>
      <StyledFiltersCollapse in={openCollapse} timeout="auto" unmountOnExit>
        {filterOptions &&
          filterOptions.map((item) => {
            if (!item) return null;
            const { titleTooltip, options } = item;
            const category = Object.keys(item)[0];
            const categoryLabel = Object.values(item)[0];
            const currOptions = options && options[0];
            return (
              <StyledFiltersButtonGroup key={`button-group-${category}`}>
                <Typography variant="h6">{categoryLabel}{ titleTooltip && (
                  <IconWithTooltip
                    icon={<InformationIcon />}
                    tooltipText={titleTooltip}
                    placement="top-end"
                  />
                )}</Typography>
                <div className="button-group-list">
                  {currOptions &&
                    Object.keys(currOptions).map((key) => {
                      const value = currOptions[
                        key as keyof typeof currOptions
                      ] as string;
                      return (
                        <Button
                          key={`button-option-${key}-${value}`}
                          className="small"
                          variant={
                            selectedFilters[category] === key
                              ? "outlined"
                              : "elevated"
                          }
                          onClick={() =>
                            handleFilterSelect(category, value, key)
                          }
                        >
                          {value}
                        </Button>
                      );
                    })}
                </div>
              </StyledFiltersButtonGroup>
            );
          })}
      </StyledFiltersCollapse>
    </>
  );
};

export default SearchFilters;
