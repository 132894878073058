'use client'
import { Box, BoxProps, Container, styled } from "@mui/material";
import theme from "@/theme/theme";

export const BenefitContentWrapper = styled(Container)<BoxProps>(() => ({
  position: "relative",
  display:"flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: theme.sizes.desktop.containerPadding,
  [`${theme.mediaQueries.desktopM}`]: {
    paddingTop: theme.sizes.containerPadding,
    flexDirection: "row",
    ".MuiContainer-root": {
      paddingLeft: 0,
    }
  },
  ".logo":{
    position: "absolute",
    top: theme.sizes.quadricMargin,
    left: theme.sizes.doubleMargin,
    [`${theme.mediaQueries.desktopM}`]: {
      top: "100px",
    }
  }, 
}));

export const BenefitCardWrapper = styled(Box)<BoxProps>(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.sizes.halfBasePadding,
    overflow: "hidden",
    [`${theme.mediaQueries.desktopM}`]: {
      padding: theme.sizes.basePadding,
    },
    ".image-wrapper": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        borderRadius: theme.sizes.radius.large,
        marginBottom: "3px",
        [`${theme.mediaQueries.desktopM}`]: {
          marginBottom: theme.sizes.baseMargin,
        },
        "> img": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
        },
    },
    "p": {
      fontSize: "14px",
      lineHeight: 1,
      height: "36px",
      [`${theme.mediaQueries.desktopM}`]: {
          fontSize: "16px",
          lineHeight: "18px"
      }
    },
}));