"use client";

import React from "react";
import { ApplicationPromotionProps } from "./types";
import Image from "next/image";
import { ApplicationPromotionWrapper, MediaWrapper } from "./styles";
import { useInView } from "react-intersection-observer";
import { delayTime, useInViewConfig } from "@/lib/animations/transitionsUtils";
import { transitionZoomFade } from "@/lib/animations/transitions/transitionZoomFade";
import { transitionFade } from "@/lib/animations/transitions/transitionFade";
import AppsButtonsComponent from "@/components/AppsButtonsComponent/AppsButtonsComponent";

const ApplicationPromotion: React.FC<ApplicationPromotionProps> = ({
  appButtons,
  data,
  media,
}) => {
  const { ref, inView } = useInView(useInViewConfig);

  const mainTransitionStyles = transitionFade(inView, delayTime * 2);
  const leftTransitionStyles = transitionZoomFade(inView, delayTime * 3);
  const rightTransitionStyles = transitionZoomFade(inView, delayTime * 4);

  const mainImage = media?.main?.image ? media.main?.image : undefined;
  const mainVideo = media?.main?.video_file
    ? media.main?.video_file
    : undefined;
  const GTMEvent = "promotinal_banner_apps";

  return (
    <section>
      <ApplicationPromotionWrapper ref={ref}>
        <AppsButtonsComponent
          data={data}
          appButtons={appButtons}
          GTMEventName={GTMEvent}
        />
        {media && (
          <MediaWrapper mainVideo={mainVideo ? true : false}>
            {mainImage && (
              <Image
                className="main-image"
                {...mainImage}
                style={{
                  ...mainTransitionStyles,
                }}
              />
            )}
            {mainVideo && (
              <video
                className="main-video"
                src={mainVideo}
                autoPlay
                loop
                muted
                playsInline
              ></video>
            )}

            {media?.left && (
              <Image
                className="left-image"
                {...media.left}
                style={{
                  ...leftTransitionStyles,
                }}
              />
            )}
            {media?.right && (
              <Image
                className="right-image"
                {...media.right}
                style={{
                  ...rightTransitionStyles,
                }}
              />
            )}
          </MediaWrapper>
        )}
      </ApplicationPromotionWrapper>
    </section>
  );
};

export default ApplicationPromotion;
