'use client'

import React from 'react';
import LottieAnimation from '@/components/reusable/LottieAnimation/LottieAnimation';
import MainContentComponent from '@/components/MainContentComponent/MainContentComponent';
import { WorkingCardsWrapper } from './styles';
import { WorkingCardsProps } from './types';

const WorkingCards: React.FC<WorkingCardsProps> = ({data, lottie}) => {
  const GTMEvent = "promotinal_banner";
  return (
    <section>
      <WorkingCardsWrapper>
        <MainContentComponent data={data} GTMEventName={GTMEvent}/>
          {lottie?.src && (
              <LottieAnimation
                  src={lottie.src}
                  className="animation-container"
              />
          )}

      </WorkingCardsWrapper>
    </section>
  );
};

export default WorkingCards;
