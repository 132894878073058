'use client'

import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import theme from "@/theme/theme";

export const WorkingCardsWrapper = styled(Container)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column-reverse",
  paddingTop: 0,
  paddingBottom: 0,
  ".MuiContainer-root": {
      paddingTop: 0,
      ".content": {
        paddingTop: 0,
      }
    },
  [`${theme.mediaQueries.desktopM}`]: {
    flexDirection: "row",
    ".MuiContainer-root": {
      paddingLeft: 0,
      paddingBottom: 0,
      ".content": {
        paddingBottom: 0,
        width: "470px"
      }
    }
  },
  ".animation-container": {
    [`${theme.mediaQueries.desktopM}`]: {
      "svg": {
        width: "auto !important"
      }
    },
  }
});