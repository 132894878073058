import React, { useState, useMemo, ReactNode, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import axios from "axios";
import debounce from "lodash.debounce";
import { SearchContainer } from "./styles";
import { SearchIcon } from "../reusable/IconsComponents";
import { Desktop } from "@/common/MediaQueries";
import useTypewriter from "@/hooks/useTypewriter";
import { useInView } from "react-intersection-observer";
import { useInViewConfig } from "@/lib/animations/transitionsUtils";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { fetchCookieSettings } from "@/lib/api/fetchData";

interface SearchResult {
  href: URL;
  label: ReactNode;
  _source: any;
  text: string;
  id: number;
  name: string;
}

const apiUrl = process.env.NEXT_PUBLIC_LOCAL_IMG_PATH;

const SearchComponent: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>("");
  const { ref, inView } = useInView(useInViewConfig);
  const typewriterPlaceholder = useTypewriter(
    searchPlaceholder,
    100,
    500,
    inView
  ); // 100ms per character
  const GTMEvent = "search";
  const fetchPlaceholderText = async () => {
    const data = await fetchCookieSettings();
    setSearchPlaceholder(data?.searchPlaceholder || "מה תרצה לחפש היום?");
  };
  useEffect(() => {
    fetchPlaceholderText();
  }, []);

  // Debounce function to delay API calls
  const fetchSearchResults = async (searchTerm: string) => {
    if (!searchTerm) {
      setResults([]); // Ensure results is always an array
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        apiUrl
          ? `/api/search?query=${searchTerm}`
          : `/he/search/suggest?keys=${searchTerm}`
      );
      // Ensure response data is an array, or transform it into an array if needed
      setResults(
        Array.isArray(response.data.data.options)
          ? response.data.data.options
          : []
      );
    } catch (error) {
      console.error("Error fetching search results:", error);
      setResults([]); // Fallback to an empty array on error
    } finally {
      setLoading(false);
    }
  };

  // Memoized debounced function to avoid excessive API calls
  const debouncedFetchResults = useMemo(
    () => debounce((searchTerm: string) => fetchSearchResults(searchTerm), 500),
    []
  );

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setQuery(value);
    debouncedFetchResults(value);
  };

  return (
    <SearchContainer ref={ref} id="search-component">
      <Autocomplete
        freeSolo
        inputValue={query}
        onInputChange={handleInputChange}
        options={results} // Ensure this is always an array
        getOptionLabel={(option) => {
          // Handle both free text (string) and SearchResult objects
          if (typeof option === "string") {
            return option;
          }
          return option?._source?.title ? option?._source?.title : "no result";
        }}
        renderOption={(props, option) => {
          const id = `search-option-${option?._source?.title}`;
          // Rendering a link in each option
          return (
            <li {...props} id={id} key={id}>
              <a
                onClick={() =>
                  handleGTMEvent(GTMEvent, id, {
                    EvenTrigger: "on load page result",
                    query_value: option?._source?.title,
                  })
                }
                href={option?._source?.url}
              >
                {SearchIcon && <SearchIcon />}
                {option?._source?.title}
              </a>
            </li>
          );
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={typewriterPlaceholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                  <Desktop>
                    <SearchIcon />
                  </Desktop>
                </>
              ),
            }}
          />
        )}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
            ],
          },
        }}
      />
    </SearchContainer>
  );
};

export default SearchComponent;
