"use client";
import theme from "@/theme/theme";
import { Dialog, dialogClasses } from "@mui/material";
import styled from "@emotion/styled";

export const PopupWrapper = styled(Dialog)`
  & .MuiBackdrop-root {
    background: ${theme.colors.popupOverlayGradient};
  }
  & .${dialogClasses.paper} {
    padding: ${theme.sizes.doublePadding};
    margin: ${theme.sizes.doubleMargin};
    ${theme.mediaQueries.desktopM} {
      padding: ${theme.sizes.triplePadding};
      margin: ${theme.sizes.tripleMargin};
      max-width: unset;
    }
  }
  .circle {
    margin-top: 0;
    margin-inline-start: auto;
    flex: 0 0 auto;
  }
`;
