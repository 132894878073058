'use client'
import { Box, BoxProps, Container, styled } from "@mui/material";
import theme from "@/theme/theme";
import { MediaWrapperProps } from "./types";

export const ApplicationPromotionWrapper = styled(Container)(() => ({
  position: "relative",
  display:"flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  paddingRight: 0,
  paddingLeft: 0,
  [`${theme.mediaQueries.desktopM}`]: {
    flexDirection: "row",
    paddingRight: theme.sizes.doublePadding,
    paddingLeft: theme.sizes.doublePadding
  }
}));

export const ContentWrapper = styled(Box)<BoxProps>(() => ({
  position: "relative",
  display:"flex",
  flexDirection: "column",
  ".MuiContainer-root": {
      paddingTop: 0,
      paddingBottom: 0,
      ".content": {
        paddingBottom: 0,
        "div:last-child": {
          marginBottom: 0
        }
      },
    },
  [`${theme.mediaQueries.desktopM}`]: {
    marginInlineEnd: theme.sizes.octoMargin,
    width: "45%",
    justifyContent: "space-between",
    ".MuiContainer-root": {
      paddingLeft: 0,
      ".content": {
        padding: 0,
        "div:last-child": {
          marginBottom: 0
        }
      },
    }
  }
}));

export const AppButtonsWrapper = styled(Container)<BoxProps>(({style}) => ({
  position: "relative",
  display:"flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  textAlign: "center",
  paddingTop: 0,
  paddingRight: 0,
  paddingLeft: 0,
  "p": {
    width: "100%",
    marginTop: theme.sizes.tripleMargin,
    marginBottom: 0,
    marginInlineStart: theme.sizes.triplePadding,
    fontSize: theme.sizes.fonts.content,
    fontWeight: "bold",
    textAlign: "start",
    [`${theme.mediaQueries.desktopM}`]: {
      marginBottom: theme.sizes.baseMargin,
      marginInlineStart: 0,
        fontSize: theme.sizes.fonts.desktop.subContent,
    }
  },
  "a": {
    padding: 0,
    "&:last-child": {
      marginInlineStart: theme.sizes.baseMargin
    }
  },
  [`${theme.mediaQueries.desktopM}`]: {
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    textAlign: "start",
    paddingTop: 0,
    paddingRight: theme.sizes.triplePadding,
    paddingLeft: theme.sizes.triplePadding
  },
  style
}));

export const MediaWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'mainVideo'
})<MediaWrapperProps>(({mainVideo}) => ({
  position: "relative",
  display:"flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.sizes.containerPadding,
  minHeight: mainVideo ? "unset" : "200px",
  [`${theme.mediaQueries.desktopM}`]: {
    paddingTop: 0,
    minHeight: "unset",
  },
  ".main-image": {
    width: "80%",
    height: "auto",
    [`${theme.mediaQueries.desktopM}`]: {
      width: "100%"
    }
  },
  ".left-image": {
    position: "absolute",
    bottom: mainVideo ? "10%" : "unset",
    right: mainVideo ? "-30%" : "60%",
    top: mainVideo ? "unset" : "20%",
    [`${theme.mediaQueries.desktopM}`]: {
      bottom: "10%",
      right: mainVideo ? "-30%" : "60%",
      top: "unset",
    }
  },
  ".right-image": {
    position: "absolute",
    top: mainVideo ? "10%" : "unset",
    bottom: "10%",
    left: mainVideo ? "-30%" : "55%",
    [`${theme.mediaQueries.desktopM}`]: {
      top: "10%",
      bottom: "unset",
      left: mainVideo ? "-30%" : "60%",
    }
  },
}));