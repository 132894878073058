import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useState,
} from "react";
import {
  FormContextType,
  FormValues,
  ResultsData,
  SearchResult,
} from "./types";

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const formValues = useRef<Record<number, FormValues>>({});
  const validationState = useRef<Record<number, Record<string, boolean>>>({});
  const [results, setResults] = useState<SearchResult[]>([]);
  const [groups, setGroups] = useState<any[] | null>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const [filteredResults, setFilteredResults] = useState<SearchResult[] | null>(
    null
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);


  const initializeTabValues = useCallback(
    (tabIndex: number, initialValues: FormValues = {}) => {
      setActiveTabIndex(tabIndex);
      if (!formValues.current[tabIndex]) {
        formValues.current[tabIndex] = initialValues;
      }
    },
    []
  );

  const updateValue = useCallback(
    (tabIndex: number, name: string, value: any) => {
      if (!formValues.current[tabIndex]) {
        initializeTabValues(tabIndex);
      }
      formValues.current[tabIndex] = {
        ...formValues.current[tabIndex],
        [name]: value,
      };
    },
    [initializeTabValues]
  );

  const getActiveTabValues = useCallback(
    (): FormValues => {
      return formValues.current[activeTabIndex] || {};
    },
    [activeTabIndex]
  );

  const updateResultsList = useCallback((newResults: ResultsData) => {
    const { results, groups, filterOptions } = newResults;
    setFilters(filterOptions);
    setResults(results);
    setFilteredResults(results);
    setGroups(groups);
  }, []);

  const updateValidationState = useCallback(
    (tabIndex: number, name: string, isValid: boolean) => {
      if (!validationState.current[tabIndex]) {
        validationState.current[tabIndex] = {};
      }
      validationState.current[tabIndex][name] = isValid;

      // Update button state based on form validity
      const isFormValidNow = Object.values(
        validationState.current[tabIndex]
      ).every(Boolean);
      setIsButtonDisabled(!isFormValidNow);
    },
    []
  );

  const isFormValid = useCallback((tabIndex: number) => {
    const tabValidation = validationState.current[tabIndex];
    if (!tabValidation) {
      return true;
    } // No validation rules = valid
    return Object.values(tabValidation).every(Boolean);
  }, []);

  return (
    <FormContext.Provider
      value={{
        updateValue,
        getActiveTabValues,
        initializeTabValues,
        activeTabIndex,
        setActiveTabIndex,
        updateResultsList,
        results,
        groups,
        filters,
        filteredResults,
        setFilteredResults,
        updateValidationState,
        isFormValid,
        setIsValid,
        isButtonDisabled,
        setIsButtonDisabled,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useFormContext hook must be used within a FormProvider component. Wrap your component tree with <FormProvider>.</FormProvider>"
    );
  }
  return context;
};
