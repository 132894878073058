"use client";

import React, { useEffect, useState } from "react";
import { IconImageCompProps } from "./types";
import { CardsWrapper } from "./styles";
import IconImageCard from "./IconImageCard";
import { Button } from "@mui/material";
import ArrowLink from "../reusable/ArrowLink/ArrowLink";
import { TwoPartOneColorTitle } from "../reusable/TwoPartsTitles/TwoPartsTitle";
import { useInView } from "react-intersection-observer";
import { delayTime, useInViewConfig } from "@/lib/animations/transitionsUtils";
import { transitionUpFade } from "@/lib/animations/transitions/transitionUpFade";
import { handleGTMEvent } from "@/common/GTMEventSender";

const IconImageCardComponent: React.FC<IconImageCompProps> = ({
  title,
  link,
  button,
  data,
  cardWidth,
  cardHeight,
  cardWidthDesktop,
  cardHeightDesktop,
  variant,
  className,
  GTMEventName,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  const [itemsLength, setItemsLength] = useState(0);
  const buttonTransitionStyles = transitionUpFade(
    inView,
    delayTime * itemsLength + 2
  );

  useEffect(() => {
    if (data.length < 0) setItemsLength(data.length);
  }, [data]);

  return (
    <CardsWrapper ref={ref}>
      {title && <TwoPartOneColorTitle title={title} />}
      <div className="item-wrapper">
        {data &&
          data.map((item, index) => {
            const cardTransitionStyles = transitionUpFade(
              inView,
              delayTime * index
            );
            return (
              <IconImageCard
                width={cardWidth}
                height={cardHeight}
                widthDesktop={cardWidthDesktop}
                heightDesktop={cardHeightDesktop}
                className={className}
                key={(item?.title ? item?.title : "") + index}
                variant={variant}
                subitems={item?.subitems ? item?.subitems : undefined}
                animationStyle={cardTransitionStyles}
                GTMEventName={GTMEventName}
                {...item}
              />
            );
          })}
      </div>
      {link && <ArrowLink href={link.href} text={link.text} />}
      {button && button?.label && (
        <Button
          variant="outlined"
          {...button}
          className="small"
          id={`${GTMEventName}-cards-button-${button.text}`}
          style={buttonTransitionStyles ? buttonTransitionStyles : {}}
          onClick={() =>
            handleGTMEvent(
              GTMEventName ? GTMEventName : "cards-outer-link-button",
              `${GTMEventName}-cards-button-${button.text}`,
              {
                item_url: button?.href ? button.href : "",
                item_category: GTMEventName,
                item_text: button.label,
              }
            )
          }
        >
          {button?.label ? button?.label : ""}
        </Button>
      )}
    </CardsWrapper>
  );
};

export default IconImageCardComponent;
