'use client'

import React, { useState, useRef } from "react";
import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ArrowLeftIcon, ArrowRightIcon } from '../reusable/IconsComponents';
import { ClockCarouselProps, reactChildType } from "./types";
import { ClockContainer, ClockItem, ClockItemsContainer, RotateButton } from "./styles";
import { handleGTMEvent } from "@/common/GTMEventSender";

const ClockCarousel = ({ children, GTMEventName }: ClockCarouselProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rotation, setRotation] = useState(0);
  const minChildrenCount = 12; // Minimum number of items required
  const itemsToShow = 3; // Number of items to always show
  const originalChildren = React.Children.toArray(children);
  const numChildren = originalChildren.length;

  // Duplicate children if there are fewer than minChildrenCount
  const childrenToDisplay = numChildren >= minChildrenCount 
    ? originalChildren
    : [...originalChildren, ...Array(minChildrenCount - numChildren).fill(null).map((_, i) => originalChildren[i % numChildren])];

  const itemAngle = 360 / childrenToDisplay.length; // Angle per item based on total items
  const carouselRef = useRef(null);

  const rotateClockwise = () => {
    setRotation((prev) => prev - itemAngle);

    handleGTMEvent(GTMEventName ? GTMEventName : 'clock-button-left', `${GTMEventName}-clock-button-left`,{
      item_url: "null",
      item_text: "left",
    })
  };

  const rotateCounterClockwise = () => {
    setRotation((prev) => prev + itemAngle);

    handleGTMEvent(GTMEventName ? GTMEventName : 'clock-button-right', `${GTMEventName}-clock-button-right`,{
      item_url: "null",
      item_text: "right",
    })
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === "ArrowLeft") {
      rotateClockwise();
    } else if (event.key === "ArrowRight") {
      rotateCounterClockwise();
    }
  };

  return (
    <ClockContainer
      ref={carouselRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role="region"
      aria-label="Clock Carousel"
    >
      <ClockItemsContainer>
        <Box>
          { childrenToDisplay.map((child: reactChildType, index: number) => {
            const angle = index * itemAngle + rotation;
            const angleMod = isMobile ? ((angle % 360) + 390) % 360 : ((angle % 360) + 360) % 360;
            const isVisible = angleMod < itemAngle * itemsToShow;
            const zIndex = isVisible ? childrenToDisplay.length - index : -index;

            return (
              <ClockItem
                key={index}
                angle={angle}
                isVisible={isVisible}
                zIndex={zIndex}
                role="img"
              >
                {child}
              </ClockItem>
            );
          })}
        </Box>
      </ClockItemsContainer>
      <RotateButton onClick={rotateClockwise} id={`${GTMEventName}-clock-button-left`} className='circle controllers left' variant='elevated' aria-label="Rotate Counter-Clockwise">
          <ArrowLeftIcon />
      </RotateButton>
      <RotateButton onClick={rotateCounterClockwise} className='circle controllers right' variant='elevated' aria-label="Rotate Clockwise">
          <ArrowRightIcon />
      </RotateButton>
    </ClockContainer>
  );
};

export default ClockCarousel;
