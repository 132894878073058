import React, { useState } from "react";
import {
  MiniCollapseWrapper,
  MiniContentWrapper,
  MiniList,
  MiniTable,
  MiniTableItem,
  MiniTableItemInner,
  MiniText,
  MiniTitle,
} from "./styles";
import { MiniContentProps } from "./types";
import { ListItem, Typography } from "@mui/material";
import theme from "@/theme/theme";

const MiniContent: React.FC<MiniContentProps> = ({
  data,
  title,
}) => {
  return (
    <MiniContentWrapper>
      {title && <MiniTitle variant="h5">{title}</MiniTitle>}
      {Object.keys(data).map((key) => {
        switch (key) {
          case "text":
            return data?.text?.map(
              (
                { text, value }: { text: string; value: string },
                index: number
              ) => (
                <MiniText key={`${text}-${index}`}>
                  {text && <h4 className="text">{'\u202A' + text + '\u202C'}</h4>}
                  {value && <h4>{'\u202A' + value + '\u202C'}</h4>}
                </MiniText>
              )
            );
          case "bullets":
            return (
              <MiniList key={`bullets-${key}`}>
                {data?.bullets?.map((item: { text: string }, index: number) => (
                  <ListItem key={`${item?.text}-${index}`}>{item?.text}</ListItem>
                ))}
              </MiniList>
            );
          case "table":
          case "items": {  // Add opening curly brace here
            const currentTable = data?.table ? data?.table : [{items: data?.items}];
            if (!currentTable) return null;
            // Check if any item has a disclaimer
            const hasDisclaimer = currentTable.some(tableItem => 
              tableItem.items?.some((item: { disclaimer: string; }) => item.disclaimer)
            );
            return (
              <MiniTable key={`table-${key}`}>
                {currentTable?.map((item, index: number) => (
                  <MiniTableItem key={`${key}-${index}`} hasDisclaimer={hasDisclaimer}>
                    {item?.items.map((text: { disclaimer: React.JSX.Element; title?: string; text: string },
                        textIndex: number
                      ) => (
                        <MiniTableItemInner
                          key={`pop-table-item-${textIndex}`}
                          className={hasDisclaimer ? "disclaimer" : ""}
                        >
                          {text?.title && (
                            <Typography variant="h4" className="title" color={text?.text ? theme.colors.mainGrey : theme.colors.mainBlue}>
                              {'\u202A' + text?.title + '\u202C'}
                            </Typography>
                          )}
                          {text?.text && (
                            <Typography variant="h5">
                              {text?.text}
                            </Typography>
                          )}
                          {text?.disclaimer && (
                            <Typography variant="disclaimer">
                              {text.disclaimer}
                            </Typography>
                          )}
                        </MiniTableItemInner>
                      )
                    )}
                  </MiniTableItem>
                ))}
              </MiniTable>
            );
          }  // Add closing curly brace here
          default:
            return null;
        }
      })}
    </MiniContentWrapper>
  );
};

export default MiniContent;
