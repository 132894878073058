import { getLink } from "./getElements";

// all Drupal relevant configuration and calls goes here
export const host = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

export const fetchMenuData = async (
  urls: string[]
): Promise<any[] | undefined> => {
  // Replace 'any' with the specific type or interface
  try {
    console.log(`[${new Date().toISOString()}] fetchMenuData - URLs:`, urls);
    const responses = await Promise.all(urls.map((url) => fetch(url)));
    if (responses.some((response) => !response.ok)) {
      //   console.error("fetchMenuData ERROR:", responses);
      return [];
    }

    const data = await Promise.all(
      responses.map((response) => response.json())
    );
    return data.map((item) => item?.linkset);
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error("An unknown error occurred.");
    }
    return [];
  }
};

export async function fetchCookieSettings() {
  try {
    console.log(`[${new Date().toISOString()}] fetchCookieSettings`);
    const response = await fetch(`${host}/_api/marketing_banner`);
    if (!response.ok) {
      return undefined;
    }
    const data = await response.json();
    const {
      cookie_expiration_period,
      cookie_domain,
      cookies_banner,
      search_placeholder,
      butterfly_text,
    } = data;
    return {
      cookieExpirationPeriod: cookie_expiration_period,
      cookieDomain: cookie_domain,
      cookiesBanner: {
        selectTitle: cookies_banner.field_select_h_title,
        links: [
          getLink(cookies_banner.field_cookies_info_link[0]),
          getLink(cookies_banner.field_banner_consent[0]),
        ],
        imageUrl: cookies_banner.field_cookies_image,
        title: cookies_banner.field_cookies_title
          ? cookies_banner.field_cookies_title
          : undefined,
      },
      butterflyText: butterfly_text,
      searchPlaceholder: search_placeholder,
    };
  } catch (error) {
    // console.error("Error fetching cookie settings:", error);
    return undefined;
  }
}
