"use client";

import { FormControl, Input, InputLabel, Typography } from "@mui/material";
import theme from "@/theme/theme";
import styled from "@emotion/styled";

export const StyledFormControl = styled(FormControl)`
  height: 100%;
  flex: 0 0 auto;
  justify-content: flex-start;
  gap: 0;
  .MuiInputBase-root {
    margin-top: 0;
    &.number {
      &:after {
        content: "₪";
        display: flex;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        pointer-events: none;
        border-bottom: none;
      }
    }
  }
    svg {
        fill: ${theme.colors.mainRed};
        width: 12px;
        stroke: ${theme.colors.mainRed};
        stroke-width: 2px;
    }
`;

export const StyledSubtitle = styled(Typography)`
  flex: 0 0 auto;
  font-size: ${theme.sizes.fonts.desktop.content};
  font-weight: 800;
`;

export const StyledInputLabel = styled(InputLabel)`
  position: relative;
  overflow: visible;
  display: flex;
  transform: none;
  line-height: 1;
  margin-top: 3px;
  &.Mui-focused {
    color: ${theme.colors.mainGrey};
  }
`;