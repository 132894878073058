"use client";

import React, { useRef, useEffect } from "react";
import { imageUrl, isLocalHost } from "@/theme/theme";
import { Button } from "@mui/material";
import Image from "next/image";
import { SearchPropsToggleProps } from "./types";
import { StyledSearchPropsToggle } from "./styles";
import ToggleTabsWrapper from "@/components/reusable/ToggleTabsWrapper/ToggleTabsWrapper";
import { useFormContext } from "../reusable/FormBits/FormContext";
import ToggleTabContent from "./ToggleTabContent";

const imgUrl = `${imageUrl}images/diamondVector.svg`;
const allDepositsUrl = `${isLocalHost ? "/api" : "/_api"}/poalim_nabat/deposits_all`;

const SearchPropsToggle: React.FC<SearchPropsToggleProps> = ({ data }) => {
  const { label, categories, depositTypesItems, button } = data;
  const activeTabIndexRef = useRef<number>(0);
  const { updateResultsList, isFormValid, isButtonDisabled, initializeTabValues, getActiveTabValues } = useFormContext();

  const handleTabChange = (index: number) => {
    activeTabIndexRef.current = index;
    initializeTabValues(index);
  };

  // Initialize first tab on mount
  useEffect(() => {
    initializeTabValues(0);
  }, []);

  const sendForm = async () => {
    if (!isFormValid(activeTabIndexRef.current)) return false;

    try {
      const activeTabValues = getActiveTabValues(
        activeTabIndexRef.current
      );
      const amount = activeTabValues.amount
        ? parseInt(activeTabValues.amount.toString())
        : 1000;
      
      const paramObject = {
        depositingAmount: amount,
        depositingMethodCode: activeTabIndexRef.current + 1,
        firstExitCode: activeTabValues.exitCode || 999,
        ...activeTabValues,
      };
      
      const response = await fetch(allDepositsUrl, { // test and prod url
        method: "POST",
        body: JSON.stringify(paramObject),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      updateResultsList(data);
      setTimeout(() => {
        const resultsElement = document.getElementById("nbt-search-results");
        if (resultsElement) {
            resultsElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    } catch (error) {
      console.error("Error sending form:", error);
      updateResultsList({ results: [], groups: [], filterOptions: [] });
    }
  };

  return (
    <StyledSearchPropsToggle>
      <Image
        className="background-image"
        src={imgUrl}
        width={230}
        height={230}
        alt={"background-image"}
      />
      <ToggleTabsWrapper
        label={label}
        tabs={categories}
        setActiveTabIndex={handleTabChange}
      >
        {depositTypesItems?.map((items, outerIndex) => (
          <ToggleTabContent
            key={`toggle-tab-content-${outerIndex}`}
            items={items}
            outerIndex={outerIndex}
          />
        ))}
      </ToggleTabsWrapper>
      {button && (
        <Button
          onClick={sendForm}
          className="small"
          disabled={isButtonDisabled}
        >
          {button?.label}
        </Button>
      )}
    </StyledSearchPropsToggle>
  );
};

export default SearchPropsToggle;
