'use client';

import React, { useState,useRef, useEffect } from 'react';
import { PlayPauseButton, VolumeOnOffButton } from '../ActionButton/ActionButton';
import { VideoBackgroundWrapper, ButtonWrapper } from './styles';
import { VideoBackgroundProps } from './types';
import ReactPlayer from 'react-player/lazy';
import { handleGTMEvent } from '@/common/GTMEventSender';
import theme from '@/theme/theme';
import { useMediaQuery } from '@mui/material';

const VideoBackground: React.FC<VideoBackgroundProps> = ({ videoUrl, videoMobileUrl, autoplay = true, height = 590, GTMEventName }) => {
    const [play, setPlay] = useState(autoplay);
    const [muted, setMuted] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currVideoUrl, setCurrVideoUrl] = useState(isMobile ? videoMobileUrl : videoUrl);
    const playerRef = useRef(null);
    const playPauseBtnId = `video-play-pause-${GTMEventName}`;
    const muteUnmutedBtnId = `video-play-pause-${GTMEventName}`;

    const handlePausePlay = () => {
        setPlay(prev => !prev);
        handleGTMEvent(GTMEventName ? GTMEventName : "play-pause-button", playPauseBtnId, {
            item_url: "null",
            item_text: !play ? "play" : "pause",
        })
    };

    const handleMuteUnmute = () => {
        setMuted(prev => !prev); // Toggle muted state
        handleGTMEvent(GTMEventName ? GTMEventName : "volume-muted-button", muteUnmutedBtnId, {
            item_url: "null",
            item_text: !muted ? "muted" : "unmuted",
        })
    };

    useEffect(() => {
        setCurrVideoUrl(isMobile ? videoMobileUrl : videoUrl);
    },[isMobile]);

    return (
        <VideoBackgroundWrapper>
            <ReactPlayer
                ref={playerRef}
                className="hero-video"
                width="100vw"
                height={height}
                url={currVideoUrl}
                muted={muted}
                playing={play}
                loop={true}
                config={{
                    file: {
                        attributes: {
                            preload: 'auto',
                        },
                        forceVideo: true,
                    }
                }}
            />
            <ButtonWrapper>
                <PlayPauseButton play={play} id={playPauseBtnId} onClick={handlePausePlay} className="circle" variant="elevated" />
                <VolumeOnOffButton play={muted} id={muteUnmutedBtnId} onClick={handleMuteUnmute} className="circle" variant="elevated" />
            </ButtonWrapper>
        </VideoBackgroundWrapper>
    );
};

export default VideoBackground;