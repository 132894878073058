"use client";

import { Box, Collapse, Container, Typography } from "@mui/material";
import theme from "@/theme/theme";
import styled from "@emotion/styled";
import { CollapseTitle } from "../Menus/CollapseMenu/styles";
import LottieAnimation from "../reusable/LottieAnimation/LottieAnimation";

export const StyledSearchResults = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.sizes.tripleMargin};
  width: 90%;
  ${theme.mediaQueries.desktopM} {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;
export const StyledSearchResultsTitle = styled(Typography)`
 position: relative;
 margin-top: ${theme.sizes.tripleMargin};
 font-size: 20px;
 ${theme.mediaQueries.desktopM} {
   margin-top: ${theme.sizes.baseMargin};
   position: absolute;
   top: ${theme.sizes.halfBasePadding};
 }
`;

export const StyledFiltersContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  ${theme.mediaQueries.desktopM} {
    padding: ${theme.sizes.basePadding};
  }
`

export const StyledNoResults = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${theme.sizes.tripleMargin};
  p{
    font-size: 18px;
  }
  ${theme.mediaQueries.desktopM} {
    gap: ${theme.sizes.octoMargin};
    padding-right: 0;
    padding-left: 0;
    p {
      font-size: 24px;
    }
  }
`;

/** Search Filters **/
export const StyledFiltersCollapseTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  h5 {
    display: flex;
    font-size: 18px;
    svg {
      fill: ${theme.colors.mainRed};
      height: 16px;
      transition: all 0.2s ease-in-out;
      transform: rotate(-90deg);
      margin-top: ${theme.sizes.halfBasePadding};
      &.open {
        transform: rotate(90deg);
      }
      margin-inline-start: auto;
      ${theme.mediaQueries.desktopM} {
        margin-inline-start: unset;
      }
    }
    ${theme.mediaQueries.desktopM} {
      margin-inline-start: auto;
    }
  }

  ${theme.mediaQueries.desktopM} {
    align-items: center;
  }
`;

export const StyledFiltersCollapse = styled(Collapse)`
  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    gap: ${theme.sizes.doubleGap};
    ${theme.mediaQueries.desktopM} {
      margin-top: 60px;
      flex-direction: row;
      width: fit-content;
    }
  }
`;
export const StyledFiltersButtonGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ${theme.sizes.basePadding};
  margin-inline-end: 0;
  ${theme.mediaQueries.desktopM} {
    margin-inline-end: ${theme.sizes.triplePadding};
    &:last-child {
      margin-inline-end: 0;
    }
  }
  h6 {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    svg {
      width: ${theme.sizes.doublePadding};
      height: ${theme.sizes.doublePadding};
      stroke: ${theme.colors.mainBlue};
      padding-inline-start: ${theme.sizes.halfBasePadding};
    }
  }
  > svg {
    fill: ${theme.colors.mainRed};
    height: ${theme.sizes.icon.xSmall};
    transition: all 0.2s ease-in-out;
    transform: rotate(90deg);
    &.open {
      transform: rotate(-90deg);
    }
  }
  .button-group-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: ${theme.sizes.gap};
    button {
      color: ${theme.colors.mainGrey};
      background-color: ${theme.colors.white};
      padding: ${theme.sizes.halfBasePadding} ${theme.sizes.doublePadding};
      margin: 0;
    }
  }
`;

/** Search Result Item **/
export const StyledSearchResultItem = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background-color: ${theme.colors.white};
  padding: ${theme.sizes.triplePadding} ${theme.sizes.basePadding};
  border: 1px solid ${theme.colors.greyCold};
  border-radius: ${theme.sizes.radius.large};
  box-shadow: ${theme.colors.dropShadow.main};
  ${theme.mediaQueries.desktopM} {
    padding: ${theme.sizes.triplePadding};
    flex-flow: row nowrap;
  }
`;

export const FlagTag = styled("span")`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  background-color: ${theme.colors.mainBlue};
  color: ${theme.colors.white};
  padding: 4px ${theme.sizes.basePadding};
  border-radius: 12px 0px 16px 0px;
`;

export const StyledSearchResultProperty = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  flex: 1 0 auto;
  font-size: 20px;
  padding: ${theme.sizes.doublePadding};
  border-block-start: 1px solid ${theme.colors.greyCold};
  ${theme.mediaQueries.desktopM} {
    padding: ${theme.sizes.triplePadding};
    border-block-start: none;
    border-inline-end: 1px solid ${theme.colors.greyCold};
    flex: 1;
    &:nth-child(2) {
      p {
        white-space: nowrap;
      }
    }
  }
  p {
    display: flex;
    flex-direction: row;
    
  }
  svg {
    width: ${theme.sizes.doublePadding};
    height: ${theme.sizes.doublePadding};
    stroke: ${theme.colors.mainBlue};
    flex: 0 0 auto;
    margin-inline-start: ${theme.sizes.halfBasePadding};
  }
  h5 {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    .MuiButton-root {
      margin: 0;
      padding: 0;
      min-width: unset;
      svg {
        stroke: ${theme.colors.mainRed};
        margin-inline-end: auto;
      }
    }
  }
  :first-of-type {
    border-block-start: none;
    flex-grow: 2;
    width: 100%;
    h5 {
      font-size: 20px;
      color: ${theme.colors.mainBlue};
    }
    ${theme.mediaQueries.desktopM} {
      padding-inline-start: 0;
    }
  }
  :last-child {
    border-inline-end: none;
    padding-inline-end: 0;
    padding-bottom: 0;
    flex-grow: 1;
    ${theme.mediaQueries.desktopM} {
      flex-grow: 2;
    }
  }
`;

/** Search Group Item **/
export const StyledSearchGroupItemTitles = styled(CollapseTitle)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0;
  .search-title-group {
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.sizes.basePadding};
    ${theme.mediaQueries.desktopM} {
      padding: 0 ${theme.sizes.triplePadding};
    }
    &:first-child {
      padding-inline-start: 0;
      border-inline-end: 1px solid ${theme.colors.greyCold};
    }
    &:nth-child(2) {
      min-width: 135px;
    }
    h6 {
      font-size: 20px;
      font-weight: 700;
    }
    h5 {
      display: flex;
      font-size: 18px;
    }
  }
  .search-group-collapse-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-inline-start: auto;
    font-size: 18px;
    ${theme.mediaQueries.desktopM} {
      gap: ${theme.sizes.basePadding};
    }
        svg {
        width: 14px;
        height: 14px;
        flex: 0 0 auto;
        stroke: ${theme.colors.mainGrey};
        fill: ${theme.colors.mainGrey};
        transform: rotate(-90deg);
          &.open {
            transform: rotate(90deg);
          }
      }
    }
`;

export const StyledSearchGroupItem = styled(Box)`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: ${theme.colors.white};
  padding: ${theme.sizes.triplePadding};
  border: 1px solid ${theme.colors.greyCold};
  border-radius: ${theme.sizes.radius.large};
  box-shadow: ${theme.colors.dropShadow.main};
  ${theme.mediaQueries.desktopM} {
    margin-bottom: ${theme.sizes.triplePadding};
  }
`;

export const StyledSearchGroupCollapseItem = styled(Collapse)`
  width: 100%;
  .MuiCollapse-wrapperInner {
    gap: ${theme.sizes.gap};
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    padding: ${theme.sizes.doublePadding} 0;
    width: 100%;
    ${theme.mediaQueries.desktopM} {
      gap: ${theme.sizes.doubleGap};
    }
    > div {
      width: 100%;
      box-shadow: none;
    }
  }
`;

export const StyledLottieAnimation = styled(LottieAnimation)`
  width: 120px;
  height: 120px;
  align-self: center;
  justify-self: center;
`;
