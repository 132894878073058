import React from "react";
import { TwoButtonsProps } from "./types";
import { StyledTwoButtons } from "./styles";
import MainContentComponent from "@/components/MainContentComponent/MainContentComponent";
import { Desktop, Mobile } from "@/common/MediaQueries";

const TwoButtons: React.FC<TwoButtonsProps> = ({
  button,
  additionalButton,
}) => {
  return (
    <StyledTwoButtons>
      <Mobile>
        <MainContentComponent
          data={[
            {
              button: button?.mobile,
              additionalButton: additionalButton?.mobile,
            },
          ]}
        ></MainContentComponent>
      </Mobile>
      <Desktop>
        <MainContentComponent
          data={[
            {
              button: button?.desktop,
              additionalButton: additionalButton?.desktop,
            },
          ]}
        ></MainContentComponent>
      </Desktop>
    </StyledTwoButtons>
  );
};

export default TwoButtons;
