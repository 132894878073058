"use client";

import { DialogContent } from "@mui/material";
import React from "react";
import { CloseButton } from "../ActionButton/ActionButton";
import { PopupWrapper } from "./styles";
import { PopupProps } from "./types";

const Popup: React.FC<PopupProps> = ({ isOpen, setIsOpen, children, additionalClose }) => {
  const onClose = () => {
    setIsOpen(false)
    if (additionalClose) additionalClose();};

  return (
    <PopupWrapper open={isOpen} onClose={onClose}>
      <CloseButton
        className={"circle"}
        variant={"elevated"}
        onClick={onClose}
      />
      <DialogContent>{children}</DialogContent>
    </PopupWrapper>
  );
};

export default Popup;
