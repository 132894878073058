"use client";

import theme from "@/theme/theme";
import styled from "@emotion/styled";

export const StyledTitleTextComponent = styled("section")`
  padding: ${theme.sizes.basePadding};
  .MuiContainer-root .content {
    width: 100%;
  }
`;
