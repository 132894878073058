"use client";

import ArrowLink from "@/components/reusable/ArrowLink/ArrowLink";
import theme from "@/theme/theme";
import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledSearchPropsToggle = styled("section")`
  align-items: flex-start;
  & section {
    align-items: flex-start;
  }
  .MuiContainer-root {
    .content {
      align-items: flex-start;
      width: 100%;
    }
  }
`;
export const StyledLinkContainer = styled(Container)`
  padding-top: 0;
  margin: ${theme.sizes.doubleMargin} auto 60px 0;
  ${theme.mediaQueries.desktopM} {
    padding: 0;
  }
`;

export const StyledArrowLink = styled(ArrowLink)`
  padding-inline-start: ${theme.sizes.basePadding};
`;
