import { useEffect, useState } from "react";

const useTypewriter = (text: string, speed = 100, delay = 1000, startAnimation = true) => {
  const [displayedText, setDisplayedText] = useState<string>(""); // Ensure it's a string
  const [isRunning, setIsRunning] = useState<boolean>(startAnimation); // Internal state to control animation

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    let timeoutId: NodeJS.Timeout | null = null;

    if (isRunning) {
      let index = 0;
      timeoutId = setTimeout(() => {
        intervalId = setInterval(() => {
          setDisplayedText(text.slice(0, index + 1));
          index += 1;
          if (index === text.length) {
            clearInterval(intervalId!);
          }
        }, speed);
      }, delay);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [text, speed, delay, isRunning]);

  useEffect(() => {
    setIsRunning(startAnimation);
    if (!startAnimation) setDisplayedText(""); // Reset the text when animation stops
  }, [startAnimation]);

  return displayedText || ""; // Always return a string, either the typed text or full text
};

export default useTypewriter;