'use client'

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme from "@/theme/theme";

export const VideoBackgroundWrapper = styled(Box)(() => ({
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    // backgroundColor: theme.colors.black,
    ".hero-video": {
        position: "absolute",
          left: 0,
          pointerEvents: "none",
          zIndex: "0",
          
    }
}));

export const ButtonWrapper = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  left: "unset",
  right: "15px",
  bottom: "250px",
  gap: "0",
  zIndex: "10",
  [`${theme.mediaQueries.desktopM}`]: {
    flexDirection: "row",
    gap: theme.sizes.gap,
    left: "80vw",
    bottom: "68px",
    right: "unset",
  },
}));