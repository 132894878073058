import { ButtonProps } from "@mui/material";
import {convertEntityUri} from "@/lib/utils/convertEntityUri";

interface Button extends ButtonProps{
    title: string;
    uri: string;
  }

export const getButton = (btnObj: Button) => {
    if(btnObj?.uri) {
        return {
            label: btnObj.title,
            href: btnObj.uri ? convertEntityUri(btnObj.uri) : "/"
        }
    } else {
        return {
            label: btnObj?.title,
        }
    }
}

export const getLink = (linkObj: { title: string; uri: string; }) => {
    return { text: linkObj?.title ? linkObj.title : "",
        href: convertEntityUri(linkObj.uri)
     };
}
  
export const getVideo = (vidObj: { uri: string; }) => {
    return convertEntityUri(vidObj.uri);
}
export const getVideoFile = (vidFileObj: { uri: { url?: string; } }) => {
    const url = vidFileObj?.uri?.url || "";
    return convertEntityUri(url);
}
  
export const getImage = (imgObj:{
    uri: any;
    resourceIdObjMeta: any; src: string; alt: string; width: number; height:number 
    }) => {
        if(imgObj?.uri?.url)
        return {
            src: `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${decodeURIComponent(imgObj?.uri?.url)}`,
            alt: imgObj?.resourceIdObjMeta?.alt,
            height: imgObj?.resourceIdObjMeta?.height,
            width: imgObj?.resourceIdObjMeta?.width,
    }
}

export const getFile = (fileObj:{
    uri: any;
    filename: string;
}) => {
    if(fileObj?.uri?.url)
        return {
            filename: fileObj?.filename,
            src: `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${decodeURIComponent(fileObj?.uri?.url)}`,

        }
}
