"use client";
import { transitionTime, transitionEase } from "../transitionsUtils";

export const transitionFade = ($inView: boolean, $delay?: number) => {
  return {
    PointerEvent: "auto",
    opacity: $inView ? 1 : 0,
    transition: `all ${transitionTime}s ${transitionEase}`,
    ...(typeof $delay === "number" && { transitionDelay: `${$delay}s` }),
  };
};
