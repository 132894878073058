"use client";

import theme from "@/theme/theme";
import styled from "@emotion/styled";
import { Box, ToggleButtonGroup, Typography } from "@mui/material";

export const StyledToggleTabsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: ${theme.sizes.fonts.desktop.content};
  font-weight: 800;
  margin-bottom: ${theme.sizes.baseMargin};
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: fit-content;
  background-color: ${theme.colors.blueLightGrey};
  border-radius: ${theme.sizes.radius.circle};
  border: 2px solid ${theme.colors.mainBlue};
  padding: 1px;
  margin-bottom: ${theme.sizes.desktop.actionBtnsBottom};
  color: ${theme.colors.mainBlue};
  .MuiButtonBase-root {
    font-size: 16px;
    border-radius: ${theme.sizes.radius.circle};
    padding: 6px ${theme.sizes.quadricPadding};
    border: none;
    &.Mui-selected, &:focus {
      background-color: ${theme.colors.mainBlue};
      color: ${theme.colors.blueLightGrey};
    }
  }
  @media (hover: none) {
    .MuiButtonBase-root {
      &.Mui-selected, &:focus, &:hover {
        background-color: ${theme.colors.mainBlue};
        color: ${theme.colors.blueLightGrey};
      }
    }
  }
`;
