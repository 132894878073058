"use client";

import React, { useLayoutEffect, useRef } from "react";
import { LeadComponentProps } from "./types";
import {
  LeadComponentWrapper,
  LeadItemsWrapper,
  StyledLeadItem,
} from "./styles";
import { Typography, useMediaQuery } from "@mui/material";
import Image from "next/image";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import { useInViewConfig } from "@/lib/animations/transitionsUtils";
import theme from "@/theme/theme";

// Register gsap plugins
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const LeadComponent: React.FC<LeadComponentProps> = ({ data }) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { ref, inView } = useInView(useInViewConfig);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useLayoutEffect(() => {
    if (inView && sectionRef?.current && componentRef?.current) {
      // Set up GSAP context to make sure the animations are scoped correctly
      if (isMobile) {
        // Setup GSAP context
        const ctx = gsap.context(() => {
          const panels = gsap.utils.toArray(".panel");

          // Create GSAP timeline to handle both odd and even animations simultaneously
          const timeline = gsap.timeline({
            scrollTrigger: {
              trigger: sectionRef.current, // Ensure the correct trigger
              pin: true,
              scrub: 1,
              end: () =>
                "+=" +
                (sectionRef?.current?.offsetHeight
                  ? sectionRef?.current?.offsetHeight
                  : 200) *
                  0.5,
              markers: false, // Set to true if you want to debug
            },
          });

          // Animate odd panels (index % 2 !== 0) top to bottom
          timeline.to(
            panels.filter(
              (_, index) => index === 0 || index === 2 || index === 5
            ), // Select odd panels
            {
              yPercent: -35, // Top to bottom movement for odd panels
              ease: "none",
              stagger: 0, // Delay between each panel's animation
            },
            0 // Start at the same time
          );

          // Animate even panels (index % 2 === 0) bottom to top
          timeline.to(
            panels.filter(
              (_, index) => index === 1 || index === 3 || index === 4
            ), // Select even panels
            {
              yPercent: 100, // Bottom to top movement for even panels
              ease: "none",
              stagger: 0, // Delay between each panel's animation
            },
            0 // Start at the same time as the odd panels
          );
        }, componentRef);
        // Cleanup GSAP context on unmount
        return () => ctx.revert();
      } else {
        const ctx = gsap.context(() => {
          const panels = gsap.utils.toArray(".containerGsap");
          gsap.to(panels, {
            xPercent: 70,
            ease: "ease",
            scrollTrigger: {
              trigger: sectionRef.current,
              pin: true,
              scrub: 2,
              end: () =>
                "+=" +
                (sectionRef?.current?.offsetWidth
                  ? sectionRef?.current?.offsetWidth
                  : 100) *
                  0.5,
              markers: false,
            },
          });
        }, componentRef);
        // Cleanup GSAP context on unmount
        return () => ctx.revert();
      }
    }
  }, [inView]);

  return (
    <section ref={ref}>
      <div ref={componentRef}>
        <LeadComponentWrapper ref={sectionRef}>
          <LeadItemsWrapper className="containerGsap">
            {data &&
              data.map((item, index: number) => {
                const titles = item.titles ? item.titles : undefined;
                let currentCardType = "";
                switch (index) {
                  case 0:
                  case 5:
                    currentCardType = "long-card";
                    break;
                  case 2:
                  case 3:
                    currentCardType = "wide-card";
                    break;
                }
                return (
                  <StyledLeadItem
                    key={`lead-item-${index}`}
                    className={`panel box-${index} ${item.color === "red" ? "red-card" : ""} ${currentCardType ? currentCardType : ""}`}
                  >
                    {titles && (
                      <div className="title-wrapper">
                        {titles.map((title, innerIndex) => (
                          <React.Fragment
                            key={`lead-title-${index}-${innerIndex}`}
                          >
                            {title?.title && (
                              <Typography className={`title`} variant="h3">
                                {title.title}
                              </Typography>
                            )}
                            {title?.sub_title && (
                              <Typography className={`sub_title`}>
                                {title.sub_title}
                              </Typography>
                            )}
                            {title?.black_title && (
                              <Typography
                                className={`black_title`}
                                variant="h2"
                              >
                                {title.black_title}
                              </Typography>
                            )}
                            {title?.red_title && (
                              <Typography
                                className={`red_title`}
                                variant="gradient"
                              >
                                {title.red_title}
                              </Typography>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    {item.image && <Image {...item.image} />}
                  </StyledLeadItem>
                );
              })}
          </LeadItemsWrapper>
        </LeadComponentWrapper>
      </div>
    </section>
  );
};

export default LeadComponent;
