"use client";

import React from "react";
import { TitleSearchPropsToggleProps } from "./types";
import {
  StyledArrowLink,
  StyledLinkContainer,
  StyledSearchPropsToggle,
} from "./styles";
import MainContentComponent from "@/components/MainContentComponent/MainContentComponent";
import SearchPropsToggle from "@/components/SearchPropsToggle/SearchPropsToggle";
import { FormProvider } from "@/components/reusable/FormBits/FormContext";
import SearchResults from "@/components/SearchResults/SearchResults";

const TitleSearchPropsToggle: React.FC<TitleSearchPropsToggleProps> = ({
  title,
  subtitle,
  searchToggle,
  link,
  twoButtons,
}) => {
  return (
    <FormProvider>
      <StyledSearchPropsToggle>
        <MainContentComponent data={[{ titleSimple:title }, { description: subtitle }]}>
          {searchToggle && <SearchPropsToggle data={searchToggle} />}
        </MainContentComponent>
      </StyledSearchPropsToggle>
      <StyledLinkContainer>
        {link && <StyledArrowLink {...link} />}
      </StyledLinkContainer>
      <StyledSearchPropsToggle>
        <SearchResults twoButtons={twoButtons}/>
      </StyledSearchPropsToggle>
    </FormProvider>
  );
};

export default TitleSearchPropsToggle;
