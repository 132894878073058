'use client';

import React, { useState } from 'react';
import ArrowLink from '@/components/reusable/ArrowLink/ArrowLink';
import { ExplosiveWrapper } from './styles';
import { Box, Container, Typography } from '@mui/material';
import { MarkIcon } from '../../reusable/IconsComponents';
import { CloseButton } from '@/components/reusable/ActionButton/ActionButton';
import { ExplosiveMessageProps } from './types';
import { handleGTMEvent } from '@/common/GTMEventSender';

const ExplosiveMessage: React.FC<ExplosiveMessageProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true)
  const GTMEvent = 'important_message';
  
  if(data && data[0]?.text && data[0].link) { // getting the entire message list and only use the first one
    return (
        <ExplosiveWrapper className={isOpen ? 'open' : 'close'}>
           <Container className="content-wrapper">
              <MarkIcon className="notification-icon" />
              <Box className="content">
                <Typography >{data[0].text}</Typography>
                { data[0].link?.href && <ArrowLink id={`${GTMEvent}-read-more`} onClick={() => 
                handleGTMEvent(GTMEvent, `${GTMEvent}-read-more`,{
                  item_url: data[0].link?.href ? data[0].link.href : "",
                  item_text: data[0].link.text,
                })} href={data[0].link?.href ? data[0].link.href : ""} text={data[0].link.text}/> }
              </Box>
              <CloseButton variant={'ghost'} id={`${GTMEvent}-close-button`} className='circle' onClick={()=> {
                setIsOpen(prev=> !prev)
                handleGTMEvent(GTMEvent, `${GTMEvent}-close-button`,{
                  item_text: "X",
                  item_category: "close-button",
                })
                }}/>
           </Container>
        </ExplosiveWrapper>
    );
  }
};

export default ExplosiveMessage;

