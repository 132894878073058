"use client";

import { Box, Container } from "@mui/material";
import theme from "@/theme/theme";
import styled from "@emotion/styled";

export const StyledSearchPropsToggle = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.sizes.doublePadding};
  border-radius: ${theme.sizes.radius.large};
  border: none;
  gap: ${theme.sizes.desktop.actionBtnsBottom};
  overflow: visible;
  button {
    flex: 0 0 auto;
  }
  .background-image {
    position: absolute;
    top: -30%;
    right: -20%;
    scale: 0.6;
    z-index: -1;
    ${theme.mediaQueries.desktopM} {
      scale: 1;
      top: -30%;
      right: -1%;
    }
  }
  ${theme.mediaQueries.desktopM} {
    flex-direction: row;
    align-items: flex-end;
    padding: ${theme.sizes.basePadding};
    border: 1px solid ${theme.colors.greyCold};
    overflow: hidden;
  }
`;

export const ToggleTabItem = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  gap: ${theme.sizes.desktop.actionBtnsBottom};
  ${theme.mediaQueries.desktopM} {
    flex-direction: row;
    height: 165px;
  }
  .MuiFormControl-root {
    width: 100%;
    border-inline-end: none;
    :last-child {
      border-inline-end: none;
    }
    ${theme.mediaQueries.desktopM} {
      width: 45%;
      padding: ${theme.sizes.quadricPadding};
      border-inline-end: 1px solid ${theme.colors.greyCold};
    }
    ${theme.mediaQueries.desktopXl} {
      width: 35%;
    }
  }
`;
