"use client";

import React from "react";
import { Button } from "@mui/material";
import { BubblesList, StyledLinkListWrapper } from "./styles";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { CloudLeftIcon, CloudRightIcon } from "../reusable/IconsComponents";
import { useInView } from "react-intersection-observer";
import { delayTime, useInViewConfig } from "@/lib/animations/transitionsUtils";
import {
  transitionOutLeftStart,
  transitionOutRightStart,
} from "@/lib/animations/transitions/transitionStart";

interface Link {
  href: string;
  text: string;
}

export interface LinkListComponentProps {
  data: Link[] | undefined;
}

const BubblesLinkListComponent: React.FC<LinkListComponentProps> = ({
  data,
}) => {
  const { ref, inView } = useInView(useInViewConfig);
  const leftCloudTransition = transitionOutLeftStart(inView, "-20%", delayTime);
  const rightCloudTransition = transitionOutRightStart(
    inView,
    "-10%",
    delayTime * 5
  );
  const GTMEvent = "tag_section";
  if (!data || data.length === 0) {
    return null; // Return null if there's no data
  }

  return (
    <StyledLinkListWrapper ref={ref} maxWidth="md" className="container">
      <CloudLeftIcon style={leftCloudTransition} className="cloud left" />
      <CloudRightIcon style={rightCloudTransition} className="cloud right" />
      <BubblesList>
        {data.map((item, index) => {
          const id = `shortcut-button-${item.text}-${index}`;
          return (
            <Button
              variant="elevated"
              className="small"
              key={item.text + index}
              id={id}
              href={item.href ? item.href : ""}
              onClick={() =>
                handleGTMEvent(GTMEvent, id, {
                  item_url: item.href ? item.href : "",
                  item_text: item.text ? item.text : "",
                })
              }
            >
              {item.text}
            </Button>
          );
        })}
      </BubblesList>
    </StyledLinkListWrapper>
  );
};

export default BubblesLinkListComponent;
