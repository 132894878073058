'use client'

import { styled, Box } from "@mui/material";
import theme from "@/theme/theme";

export const ExplosiveWrapper = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    width: '100%',
    height: "fit-content",
    background: theme.colors.greyCold,
    color: theme.colors.mainGrey,
    stroke: theme.colors.mainGrey,
    borderBottom: `2px solid ${theme.colors.mainRed}`,
    padding: theme.sizes.basePadding,
    opacity: 1,
    transition: "all 0.3s ease-in-out",
    "&.close": {
        height: 0,
        minHeight: 0,
        opacity: 0,
        padding: 0,
        margin: 0,
        stroke: theme.colors.mainGrey,
        border: 0,
    },
   ".content-wrapper": {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        width: "100%",
        minHeight: "82px",
        gap: theme.sizes.gap,
        padding: `${theme.sizes.doublePadding} 0`,
        ".content": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "start",
        }
   },
   ".notification-icon": {
    marginInlineStart: "auto"
   },
   "svg": {
       stroke: theme.colors.mainGrey,
       fill: theme.colors.mainGrey,
       fontSize: "19px"
    },
    "a": {
        marginTop: theme.sizes.baseMargin,
        "svg": {
            fontSize: "10px"
        }
    },
    ".circle": {
        display: "flex",
        margin: 0,
        justifyContent: "center",
        alignItems: "flex-start",
        "svg": {
            fontSize: "14px",
        },
        "&:hover, &:focus, &:focus-visible": {
            border: "none"
        }
    }
}));
