'use client'

import { Box, Container, styled } from '@mui/material';
import theme from "@/theme/theme";

export const StyledLinkListWrapper= styled(Box)(() => ({
    position: "relative",
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    maxWidth: "unset",
    alignItems: 'center',
    justifyContent: 'center',
    ".cloud": {
        position: "absolute",
        width: "153px",
        height: "auto",
        bottom: 0,
        top: "unset",
        left: "unset",
        right: 0,
        zIndex: "-1",
        [`${theme.mediaQueries.desktopM}`]: {
            top: 0,
            bottom: "unset"
        },
        "&.right": {
            right: "unset",
            left: 0,
            width: "90px",
        }
    }  
}))

export const BubblesList = styled(Container)(() => ({
    "&.MuiContainer-root": {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: "8px",
        margin: 0,
        padding: `${theme.sizes.basePadding} 0 ${theme.sizes.quadricPadding} 0`,
        width: "100%",
        [`${theme.mediaQueries.desktopM}`]: {
            width: "90%",
            margin: `${theme.sizes.doubleMargin}`,
            padding: `${theme.sizes.quadricPadding} 0`,
            borderBottom: `${theme.colors.mainLightGrey} 2px solid`,
            gap: theme.sizes.gap,
        },
        "a": {
            display: "flex",
            flex: "0 0 auto",
            flexDirection: "row",
            color: theme.colors.mainGrey,
            whiteSpace: "nowrap",
            cursor: "pointer",
            border: `1px solid ${theme.colors.greyCold}`,
            boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.06)",
            margin: 0,
            padding: `${theme.sizes.basePadding} ${theme.sizes.doublePadding}`,
        }
    }
}))