'use client'
import { styled, Box, Container } from "@mui/material";
import theme from "@/theme/theme";

export const CarouselWrapper = styled(Box)(() => ({
    position: "relative",
    width: '100%',
    height: '100%',
    alignItems: "center",
    ".swiper-wrapper": {
        transitionTimingFunction: "ease-in-out",
        alignItems:"flex-start"
    },
    ".swiper-pagination": {
        position: "absolute",
        width: "fit-content",
        left: "unset",
        right: '15px',
        bottom: "90px",
        transform: "rotate(90deg)",
        [`${theme.mediaQueries.desktopM}`]: {
            transform: "rotate(0deg)",
            maxWidth: "1024px",
            width: "100%",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            left: "150px",
            right: "unset",
            bottom: "89px",
            alignItems: "flex-start",
        },
        [`${theme.mediaQueries.desktop}`]: {
            right: "0",
            left: "80px",
        },
        [`${theme.mediaQueries.desktopXl}`]: {
            right: "150px",
        },
        ".swiper-pagination-bullet": {
            background: theme.colors.greyCold,
            opacity: "1",
            width: "5px",
            height: "5px",
            transition: "all 0.3s ease-in-out",
            "&.swiper-pagination-bullet-active": {
                background: theme.colors.mainRed,
                width: "15px",
                borderRadius: "5px",
            }
        }
    }
}));

export const ButtonWrapper = styled(Container)(() => ({
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    bottom: theme.sizes.quadricMargin,
    right: 0,
    left: 0,
    gap: theme.sizes.gap,
    paddingInlineEnd: theme.sizes.quadricPadding,
    width: "100%",
    zIndex: '5',
    pointerEvents: "none",
    [`${theme.mediaQueries.desktopM}`]: {
        right: theme.sizes.octoMargin,
        left: theme.sizes.octoMargin,
    },
    "&.MuiContainer-root": {
        paddingBottom: "unset",
        paddingLeft: 0,
        paddingRight: 0,
    },
    ".circle": {
        display: "flex",
        alignContent: "center",
        justifyItems: "center",
        padding: 0,
        pointerEvents: "all",
        "svg": {
            stroke: theme.colors.mainGrey,
            pointerEvents: 'none',
            width: "14px",
        },
        '&.controllers': {
            width: "35px",
            height: "35px",
            padding: 0,
        },
        '&:first-of-type': {
            marginInlineStart: 'auto',
        },
        '&:last-of-type': {
            marginInlineEnd: theme.sizes.doubleMargin,
        }
    },
    "&.buttons-to-right": {
        [`${theme.mediaQueries.desktopM}`]: {
            paddingBottom: "35px",
            paddingLeft: theme.sizes.basePadding,
        },
        ".circle": {
            '&:first-of-type': {
                marginInlineStart: theme.sizes.doubleMargin,
                [`${theme.mediaQueries.desktopM}`]: {
                    marginInlineStart: 0,
                }
                
            },
            '&:last-child': {
                marginInlineStart: 0,
                [`${theme.mediaQueries.desktopM}`]: {
                    marginInlineEnd: 'auto',
                }
            }
        }
    }
}));