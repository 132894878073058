"use client"

import React, { useEffect } from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { LabelSelectProps } from "./types";
import { ArrowDownIcon } from "../../IconsComponents";
import { StyledFormControl, StyledSubtitle } from "../styles";

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  options,
  onChange,
  value,
}) => {
  
  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    const validationState = newValue ? true : false;
    onChange(newValue, validationState);
  };
  
  useEffect(() => {
      onChange(String(options[0].value), true);
  }, []);
  
  return (
    <StyledFormControl variant="standard">
      {label && <StyledSubtitle variant="body2">{label}</StyledSubtitle>}
      <Select
        value={value}
        defaultValue={String(options?.[0].value)}
        onChange={handleChange}
        label={label}
        IconComponent={ArrowDownIcon}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={`select-option-${option.text}-${index}`}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default LabelSelect;
