'use client'

import React, { useEffect, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper styles
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Button } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from '../reusable/IconsComponents';
import { ButtonWrapper, CarouselWrapper } from './styles';
import { PlayPauseButton } from '../reusable/ActionButton/ActionButton';
import { CarouselProps } from './types';
import { handleGTMEvent } from '@/common/GTMEventSender';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  
const CarouselComponent = ({ children,
                             buttons=true,
                             pagination=false,
                             autoplay=true,
                             speed=3000,
                             delay=5000,
                             height,
                             buttonsToRight=false,
                             ghostPlayPause,
                             GTMEventName
                            }: CarouselProps) => {
    const swiperRef = useRef<SwiperCore | null>(null);
    const [play, setPlay] = useState(autoplay);
    const prevBtnId = `carousel-prev-${GTMEventName}`;
    const nextBtnId = `carousel-next-${GTMEventName}`;
    const playPauseBtnId = `carousel-play-pause-${GTMEventName}`;

    const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
    
    handleGTMEvent(GTMEventName ? GTMEventName : "prev-button", prevBtnId, {
      item_url: "null",
      item_text: "prev",
      })
    };

    const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();

    handleGTMEvent(GTMEventName ? GTMEventName : "next-button", nextBtnId, {
      item_url: "null",
      item_text: "next",
      })
    };
    
    const handlePausePlay = () => {
      setPlay(prev => !prev)

      handleGTMEvent(GTMEventName ? GTMEventName : "play-pause-button", playPauseBtnId, {
        item_url: "null",
        item_text: !play ? "play" : "pause",
      })
    }

  useEffect(()=> {
    if(swiperRef.current) {
      if(play) swiperRef.current.autoplay.start();
      else swiperRef.current.autoplay.stop();
    }
  }, [play])

  useEffect(()=> {
    if(pagination === true) {
      const bullets = document.querySelectorAll('.swiper-pagination-bullet');
      bullets.forEach((bullet, index) => {
        bullet.id = `bullet-${index}`;
        bullet.addEventListener('click', (event) => {
          const elId = (event.currentTarget as HTMLElement).id;
            handleGTMEvent(GTMEventName ? GTMEventName : 'cards-outer-link-button', elId,{
            item_url: "null",
            item_text: "next",
          })
        });
      });
    }
  },[])

    return (
      <CarouselWrapper>
        { children && Array.isArray(children) && children.length > 1 ?
        <>
        <Swiper
            onSwiper={(swiper) => {
                swiperRef.current = swiper;
            }}
            height={ height }
            spaceBetween={20}
            slidesPerView={1}
            autoHeight={false}
            pagination={ pagination ?
              {
                type: "bullets",
                clickable: true,
              } : false
            } 
            autoplay={ autoplay }
            loop={ true }
            speed={ speed }
            modules={[Autoplay, Pagination]}>
          { children.map((child, index) => {
              if(React.isValidElement(child)) {
                return (
                <SwiperSlide data-swiper-autoplay={child?.props?.timer ? (child?.props?.timer * 1000) : delay} key={index.toString()}>
                  {child}
                </SwiperSlide>
                ) 
              }
           })
          }
        </Swiper>
          <ButtonWrapper className={buttonsToRight ? 'buttons-to-right' : ''}>
          { buttons &&
            <>
              <Button id={prevBtnId} onClick={handlePrev} className='circle controllers' variant='elevated'>
                <ArrowRightIcon />
              </Button>
              <Button id={nextBtnId} onClick={handleNext} className='circle controllers' variant='elevated'>
                <ArrowLeftIcon />
              </Button>
            </>
            }
            { autoplay && <PlayPauseButton play={play}
                                           id={playPauseBtnId}
                                           ghostPlayPause={ ghostPlayPause }
                                           onClick={ handlePausePlay }
                                           className='circle play-pause'
                                           variant={'ghost'}/>
                                           }
          </ButtonWrapper>
          
          </>
         : children }
      </CarouselWrapper>
    );
  };
  
  export default CarouselComponent;