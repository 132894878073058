"use client";
import { styled } from "@mui/material";

export const MarketingWrapper = styled("section")(() => ({
  overflow: "hidden",
  ".MuiContainer-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
